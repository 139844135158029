/*
Note:
"rate" brackets must start on the same value as the end 37000 -> 37000
"percent" brackets are one doller over the previous bracket  37000 -> 37001

nonConcesssionalCap is misspet with three s
Appologies to future self
*/


// ASCII headings
// https://patorjk.com/software/taag/#p=display&h=0&v=0&f=Fraktur&t=2023

/*
===================================================
CRITICAL RESOURCES

Schedule 1 – Statement of formulas for calculating amounts to be withheld
https://www.ato.gov.au/rates/schedule-1---statement-of-formulas-for-calculating-amounts-to-be-withheld/



Medicare levy thresholds
https://atotaxrates.info/individual-tax-rates-resident/medicare-levy/

===================================================
*/


export const taxData = [

  // {
  //   "year": "2012","current":"false",
  //   "tax": {
  // "label": "Australian Resident",
  // 	  "incremental":1,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":6000, 	"type":"percent", "nearest":1, "value":0},
  // 	  {"from":6001, 	"to":37000, 	"type":"percent", "nearest":1, "value":15},
  // 	  {"from":37001, 	"to":80000, 	"type":"percent", "nearest":1, "value":30},
  // 	  {"from":80001, 	"to":180000, 	"type":"percent", "nearest":1, "value":37},
  // 	  {"from":180001, 	"to":0, 		"type":"percent", "nearest":1, "value":45}]
  //   },
  //   "taxNoFreeThreshold": {
  // "label": "No Tax-Free Threshold",// 	  "incremental":1,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":37000, 	"type":"percent", "nearest":1, "value":15},
  // 	  {"from":37000, 	"to":80000, 	"type":"percent", "nearest":1, "value":30},
  // 	  {"from":80000, 	"to":180000, 	"type":"percent", "nearest":1, "value":37},
  // 	  {"from":180000, 	"to":0, 		"type":"percent", "nearest":1, "value":45}]
  //   },

  //   "taxNonResident": {
  // "label": "Non Resident",
  // 	  "incremental":1,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":80000, 	"type":"percent", "nearest":1, "value":30},
  // 	  {"from":80000, 	"to":180000, 	"type":"percent", "nearest":1, "value":37},
  // 	  {"from":180000, 	"to":0, 		"type":"percent", "nearest":1, "value":45}]
  //   },
  //   "lito":{
  // 	  "incremental":1,
  // 	  "paygLoading":0.7,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":29999, 	"type":"fixed", 	"nearest":1, "value":"-1500"},
  // 	  {"from":29999, 	"to":67500, 	"type":"percent", 	"nearest":1, "value":4}
  // 	  ]
  //   },

  //   "lito":{
  // 	  "incremental":0,
  // 	  "paygLoading":1.0,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":29999, 	"type":"fixed", 	"nearest":1, "value":"1500"},
  //     {"from":29999, 	"to":67500, 	"type":"rate", 	"nearest":1, "start":1500, "end":0, "value":-4},
  //     {"from":67500, 	"to":0, 	"type":"fixed", 	"nearest":1, "value":0}
  // 	  ]
  //   },


  //   "mawto":{
  // 	  "incremental":0,
  // 	  "paygLoading":1.0,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":0, 	"type":"fixed", 	"nearest":1, "value":0}
  // 	  ]
  //   },
  //   "medicare":{
  // 	  "incremental":1,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":19404, 	"type":"percent", 	"nearest":1, "value":0},
  // 	  {"from":19404, 	"to":22828, 	"type":"percent", 	"nearest":1, "value":10.0},
  // 	  {"from":22828, 	"to":0, 		"type":"percent", 	"nearest":1, "value":1.5, "incremental":"false"}
  // 	  ]
  //   },
  //   "medicareSurcharge":{
  // 	  "incremental":0,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":70000, 	"type":"percent", 	"nearest":1, "value":0},
  // 	  {"from":70000, 	"to":0, 		"type":"percent", 	"nearest":1, "value":1.25}
  // 	  ]
  //   },
  //   "superannuation":{
  // 	  "incremental":0,
  // 	  "cap":16651.60,
  // 	  "cap_50":50000,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":0, 	"type":"percent", 	"nearest":1, "value":9}
  // 	  ]
  //   },
  //   "division293":{
  // 	  "incremental":1,
  // "threshold":300000,
  //     "rate":15,
  // 	  "brackets":[
  //       {"from":0, 		"to":300000, 	"type":"percent", 	"nearest":1, "value":0.0},
  //       {"from":300000, 		"to":0, 	"type":"percent", 	"nearest":1, "value":15.0}
  // 	  ]
  //   },
  //   "other":[{
  // 		  "name":"Flood Levy",
  // 		  "incremental":1,
  // 		  "brackets":[
  // 		  {"from":0, 		"to":50000, 	"type":"fixed", 	"nearest":1, "value":0},
  // 		  {"from":50000, 	"to":1000000, 	"type":"percent", 	"nearest":1, "value":0.5},
  // 		  {"from":100000, 	"to":0, 		"type":"percent", 	"nearest":1, "value":1.0}
  // 		  ]
  // 	}
  //   ],
  //   "help":{
  // 	  "incremental":0,
  // 	  "brackets":[
  // 		{"from":0, 		"to":47196, 	"type":"fixed", 	"nearest":1, 	"value":0},
  // 		{"from":47196, 	"to":52572, 	"type":"percent", 	"nearest":1, 	"value":4},
  // 		{"from":52572, 	"to":57947, 	"type":"percent", 	"nearest":1, 	"value":4.5},
  // 		{"from":57947, 	"to":60993, 	"type":"percent", 	"nearest":1, 	"value":5},
  // 		{"from":60993, 	"to":65563, 	"type":"percent", 	"nearest":1, 	"value":5.5},
  // 		{"from":65563, 	"to":71006, 	"type":"percent", 	"nearest":1, 	"value":6},
  // 		{"from":71006, 	"to":74743, 	"type":"percent", 	"nearest":1, 	"value":6.5},
  // 		{"from":74743, 	"to":82253, 	"type":"percent", 	"nearest":1,	"value":7},
  // 		{"from":82253, 	"to":87649, 	"type":"percent", 	"nearest":1, 	"value":7.5},
  // 		{"from":87649, 	"to":0, 		"type":"percent", 	"nearest":1, 	"value":8}
  // 	  ]
  //   },
  //   "sfss":{
  // 	  "incremental":0,
  // 	  "brackets":[
  // 	  {"from":0, 		"to":47196, 	"type":"fixed", 	"nearest":1, 	"value":0},
  // 	  {"from":47196, 	"to":57947, 	"type":"percent", 	"nearest":1, 	"value":2},
  // 	  {"from":57947, 	"to":82253, 	"type":"percent", 	"nearest":1, 	"value":3},
  // 	  {"from":82253, 	"to":0, 		"type":"percent", 	"nearest":1, 	"value":4}
  // 	  ]
  //   }
  // },


  /*
    {
      "year": "2013", "current": "false",
      "tax": {
        "label": "Australian Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 18200, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 37000, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }]
      },
      "taxNoFreeThreshold": {
        "label": "No Tax-Free Threshold",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 37000, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }]
      },
  
      "taxNonResident": {
        "label": "Non Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }]
      },
      "lito": {
        "incremental": 0,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "445" },
          { "from": 37000, "to": 66667, "type": "rate", "nearest": 1, "start": 445, "end": 0, "value": -1.5 },
          { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
        ]
      },
      "mawto": {
        "incremental": 1,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 10000, "type": "percent", "nearest": 1, "value": -5, "incremental": "false" },
          { "from": 10000, "to": 53000, "type": "fixed", "nearest": 1, "value": -500, "incremental": "true" },
          { "from": 53000, "to": 63000, "type": "percent", "nearest": 1, "value": 5, "incremental": "true" },
          { "from": 63000, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "medicare": {
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 20541, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 20541, "to": 24167, "type": "percent", "nearest": 1, "value": 10.0 },
          { "from": 24167, "to": 0, "type": "percent", "nearest": 1, "value": 1.5, "incremental": "false" }
        ]
      },
      "medicareSurcharge": {
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 84000, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 84001, "to": 97000, "type": "percent", "nearest": 1, "value": 1.0 },
          { "from": 97001, "to": 130000, "type": "percent", "nearest": 1, "value": 1.25 },
          { "from": 130001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
        ]
      },
      "other": [],
      "superannuation": {
        "incremental": 0,
        "cap": 183000,
        "cap_50": 25000,
        "brackets": [
          { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 9 }
        ]
      },
      "help": {
        "label":"HECS, HELP, VET, SSL, TSL",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 49096, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 49096, "to": 54688, "type": "percent", "nearest": 1, "value": 4 },
          { "from": 54688, "to": 60279, "type": "percent", "nearest": 1, "value": 4.5 },
          { "from": 60279, "to": 63448, "type": "percent", "nearest": 1, "value": 5 },
          { "from": 63448, "to": 68202, "type": "percent", "nearest": 1, "value": 5.5 },
          { "from": 68202, "to": 73864, "type": "percent", "nearest": 1, "value": 6 },
          { "from": 73864, "to": 77751, "type": "percent", "nearest": 1, "value": 6.5 },
          { "from": 77751, "to": 85564, "type": "percent", "nearest": 1, "value": 7 },
          { "from": 85564, "to": 91177, "type": "percent", "nearest": 1, "value": 7.5 },
          { "from": 91177, "to": 0, "type": "percent", "nearest": 1, "value": 8 }
        ]
      },
      "sfss": {
        "label":"Student Financial Supplement Scheme",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 49096, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 49096, "to": 60279, "type": "percent", "nearest": 1, "value": 2 },
          { "from": 60279, "to": 85564, "type": "percent", "nearest": 1, "value": 3 },
          { "from": 85564, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
        ]
      }
    },
    {
      "year": "2014", "current": "false",
      "tax": {
        "label": "Australian Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 18200, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 37000, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
        "payg": [
          { "income": 355, "a": 0, "b": 0 },
          { "income": 395, "a": 0.19, "b": 67.4635 },
          { "income": 464, "a": 0.29, "b": 106.9673 },
          { "income": 711, "a": 0.205, "b": 67.4636 },
          { "income": 1282, "a": 0.3427, "b": 165.4424 },
          { "income": 1538, "a": 0.34, "b": 161.9809 },
          { "income": 3461, "a": 0.385, "b": 231.2116 },
          { "income": 0, "a": 0.465, "b": 508.1347 }]
  
      },
      "taxNoFreeThreshold": {
        "label": "No Tax-Free Threshold",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 37000, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
        "payg": [
          { "income": 45, "a": 0.19, "b": 0.19 },
          { "income": 361, "a": 0.2216, "b": 1.4232 },
          { "income": 932, "a": 0.3427, "b": 45.2055 },
          { "income": 1188, "a": 0.34, "b": 42.6890 },
          { "income": 3111, "a": 0.385, "b": 96.1698 },
          { "income": 0, "a": 0.465, "b": 345.0928 }
        ]
      },
      "taxNonResident": {
        "label": "Non Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
        "payg": [
          { "income": 1538, "a": 0.325, "b": 0.325 },
          { "income": 3461, "a": 0.37, "b": 69.2308 },
          { "income": 0, "a": 0.45, "b": 346.1538 }]
  
      },
      "lito": {
        "incremental": 0,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "445" },
          { "from": 37000, "to": 66667, "type": "rate", "nearest": 1, "start": 445, "end": 0, "value": -1.5 },
          { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
        ]
      },
      "mawto": {
        "incremental": 1,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 10000, "type": "percent", "nearest": 1, "value": -5, "incremental": "false" },
          { "from": 10000, "to": 53000, "type": "fixed", "nearest": 1, "value": -500, "incremental": "true" },
          { "from": 53000, "to": 63000, "type": "percent", "nearest": 1, "value": 5, "incremental": "true" },
          { "from": 63000, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "medicare": {
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 20541, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 20542, "to": 24167, "type": "percent", "nearest": 1, "value": 10.0 },
          { "from": 24168, "to": 0, "type": "percent", "nearest": 1, "value": 1.5, "incremental": "false" }
        ]
      },
      "medicareSurcharge": {
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 88000, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 88001, "to": 102000, "type": "percent", "nearest": 1, "value": 1.0 },
          { "from": 102001, "to": 136000, "type": "percent", "nearest": 1, "value": 1.25 },
          { "from": 136001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
        ]
      },
      "other": [],
      "superannuation": {
        "incremental": 0,
        "cap": 192160,
        "cap_50": 35000,
        "brackets": [
          { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 9.25 }
        ]
      },
      "division293": {
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 300000, "type": "percent", "nearest": 1, "value": 0.0 },
          { "from": 300000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
        ]
      },
      "help": {
        "label":"HECS, HELP, VET, SSL, TSL",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 51309, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 51309, "to": 57153, "type": "percent", "nearest": 1, "value": 4 },
          { "from": 57153, "to": 62997, "type": "percent", "nearest": 1, "value": 4.5 },
          { "from": 62997, "to": 66308, "type": "percent", "nearest": 1, "value": 5 },
          { "from": 66308, "to": 71277, "type": "percent", "nearest": 1, "value": 5.5 },
          { "from": 71277, "to": 77194, "type": "percent", "nearest": 1, "value": 6 },
          { "from": 77194, "to": 81256, "type": "percent", "nearest": 1, "value": 6.5 },
          { "from": 81256, "to": 89421, "type": "percent", "nearest": 1, "value": 7 },
          { "from": 89421, "to": 95287, "type": "percent", "nearest": 1, "value": 7.5 },
          { "from": 95287, "to": 0, "type": "percent", "nearest": 1, "value": 8 }
        ]
      },
      "sfss": {
        "label":"Student Financial Supplement Scheme",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 49096, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 49096, "to": 60279, "type": "percent", "nearest": 1, "value": 2 },
          { "from": 60279, "to": 85564, "type": "percent", "nearest": 1, "value": 3 },
          { "from": 85564, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
        ]
      }
    },
    {
      "year": "2015", "current": "false",
      "tax": {
        "label": "Australian Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 18200, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 37000, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
        "payg": [
          { "income": 355, "a": 0, "b": 0 },
          { "income": 395, "a": 0.19, "b": 67.4635 },
          { "income": 464, "a": 0.29, "b": 106.9673 },
          { "income": 711, "a": 0.21, "b": 67.4642 },
          { "income": 1282, "a": 0.3477, "b": 165.4431 },
          { "income": 1538, "a": 0.3450, "b": 161.9815 },
          { "income": 3461, "a": 0.3900, "b": 231.2123 },
          { "income": 0, "a": 0.49, "b": 577.3662 }
        ]
  
      },
      "taxNoFreeThreshold": {
        "label": "No Tax-Free Threshold",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 37000, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
        "payg": [
          { "income": 45, "a": 0.19, "b": 0.19 },
          { "income": 361, "a": 0.2321, "b": 1.8961 },
          { "income": 932, "a": 0.3477, "b": 43.69 },
          { "income": 1188, "a": 0.3450, "b": 41.1734 },
          { "income": 3111, "a": 0.3900, "b": 94.6542 },
          { "income": 0, "a": 0.49, "b": 405.8080 }
        ]
  
      },
      "taxNonResident": {
        "label": "Non Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
        "payg": [
          { "income": 1538, "a": 0.325, "b": 0.325 },
          { "income": 3461, "a": 0.37, "b": 69.2308 },
          { "income": 0, "a": 0.45, "b": 346.1538 }
        ]
  
      },
      "lito": {
        "incremental": 0,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "445" },
          { "from": 37000, "to": 66667, "type": "rate", "nearest": 1, "start": 445, "end": 0, "value": -1.5 },
          { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
        ]
      },
      "mawto": {
        "incremental": 1,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
      },
      "medicare": {
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 20896, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 20896, "to": 26120, "type": "percent", "nearest": 1, "value": 10.0 },
          { "from": 26120, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
        ]
      },
      "medicareSurcharge": {
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 90001, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
          { "from": 105001, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
          { "from": 140001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
        ]
      },
      "other": [
        {
          "name": "Temporary Budget Repair levy",
          "incremental": 1,
          "brackets": [
            { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
            { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 2.0 }
          ]
        }
  
      ],
      "superannuation": {
        "incremental": 0,
        "cap": 197720,
        "cap_max": 30000,
        "brackets": [
          { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 9.5 }
        ]
      },
      "division293": {
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 300000, "type": "percent", "nearest": 1, "value": 0.0 },
          { "from": 300000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
        ]
      },
      "help": {
        "label":"HECS, HELP, VET, SSL, TSL",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 53345, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 53345, "to": 59421, "type": "percent", "nearest": 1, "value": 4 },
          { "from": 59421, "to": 65497, "type": "percent", "nearest": 1, "value": 4.5 },
          { "from": 65497, "to": 68939, "type": "percent", "nearest": 1, "value": 5 },
          { "from": 68939, "to": 74105, "type": "percent", "nearest": 1, "value": 5.5 },
          { "from": 74105, "to": 80257, "type": "percent", "nearest": 1, "value": 6 },
          { "from": 80257, "to": 84481, "type": "percent", "nearest": 1, "value": 6.5 },
          { "from": 84481, "to": 92970, "type": "percent", "nearest": 1, "value": 7 },
          { "from": 92970, "to": 99069, "type": "percent", "nearest": 1, "value": 7.5 },
          { "from": 99069, "to": 0, "type": "percent", "nearest": 1, "value": 8 }
        ]
      },
      "sfss": {
        "label":"Student Financial Supplement Scheme",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 53345, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 53345, "to": 65497, "type": "percent", "nearest": 1, "value": 2 },
          { "from": 65497, "to": 92970, "type": "percent", "nearest": 1, "value": 3 },
          { "from": 92970, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
        ]
      }
    },
    
    {
      "year": "2016", "current": "false",
      "tax": {
        "label": "Australian Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 18200, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 37000, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
        "payg": [
          { "income": 355, "a": 0, "b": 0 },
          { "income": 395, "a": 0.19, "b": 67.4635 },
          { "income": 464, "a": 0.29, "b": 106.9673 },
          { "income": 711, "a": 0.21, "b": 67.4642 },
          { "income": 1282, "a": 0.3477, "b": 165.4431 },
          { "income": 1538, "a": 0.3450, "b": 161.9815 },
          { "income": 3461, "a": 0.3900, "b": 231.2123 },
          { "income": 0, "a": 0.49, "b": 577.3662 }
        ]
  
      },
      "taxNoFreeThreshold": {
        "label": "No Tax-Free Threshold",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
          { "from": 37000, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
        "payg": [
          { "income": 45, "a": 0.19, "b": 0.19 },
          { "income": 361, "a": 0.2321, "b": 1.8961 },
          { "income": 932, "a": 0.3477, "b": 43.69 },
          { "income": 1188, "a": 0.3450, "b": 41.1734 },
          { "income": 3111, "a": 0.3900, "b": 94.6542 },
          { "income": 0, "a": 0.49, "b": 405.8080 }
        ]
  
      },
      "taxNonResident": {
        "label": "Non Resident",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 80000, "type": "percent", "nearest": 1, "value": 32.5 },
          { "from": 80000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  
        "payg": [
          { "income": 1538, "a": 0.325, "b": 0.325 },
          { "income": 3461, "a": 0.37, "b": 69.2308 },
          { "income": 0, "a": 0.47, "b": 415.3846 }
        ]
  
  
      },
      "lito": {
        "incremental": 0,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "445" },
          { "from": 37000, "to": 66667, "type": "rate", "nearest": 1, "start": 445, "end": 0, "value": -1.5 },
          { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
        ]
      },
      "mawto": {
        "incremental": 1,
        "paygLoading": 1.0,
        "brackets": [
          { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
      },
      "medicare": {
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 21335, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 21335, "to": 26668, "type": "percent", "nearest": 1, "value": 10.0 },
          { "from": 26668, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
        ]
      },
      "medicareSurcharge": {
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 90001, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
          { "from": 105001, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
          { "from": 140001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
        ]
      },
      "other": [
        {
          "name": "Temporary Budget Repair levy",
          "incremental": 1,
          "brackets": [
            { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
            { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 2.0 }
          ]
        }
  
      ],
      "superannuation": {
        "incremental": 0,
        "cap": 203240,
        "cap_50": 35000,
        "brackets": [
          { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 9.5 }
        ]
      },
      "division293": {
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 300000, "type": "percent", "nearest": 1, "value": 0.0 },
          { "from": 300000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
        ]
      },
      "help": {
        "label":"HECS, HELP, VET, SSL, TSL",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 54126, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 54126, "to": 60293, "type": "percent", "nearest": 1, "value": 4 },
          { "from": 60293, "to": 66457, "type": "percent", "nearest": 1, "value": 4.5 },
          { "from": 66457, "to": 69950, "type": "percent", "nearest": 1, "value": 5 },
          { "from": 69950, "to": 75191, "type": "percent", "nearest": 1, "value": 5.5 },
          { "from": 75191, "to": 81433, "type": "percent", "nearest": 1, "value": 6 },
          { "from": 81433, "to": 85719, "type": "percent", "nearest": 1, "value": 6.5 },
          { "from": 85719, "to": 94332, "type": "percent", "nearest": 1, "value": 7 },
          { "from": 94332, "to": 100520, "type": "percent", "nearest": 1, "value": 7.5 },
          { "from": 100520, "to": 0, "type": "percent", "nearest": 1, "value": 8 }
        ]
      },
      "help_noTaxFree": {
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 35926, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 35926, "to": 42093, "type": "percent", "nearest": 1, "value": 4 },
          { "from": 42093, "to": 48257, "type": "percent", "nearest": 1, "value": 4.5 },
          { "from": 48257, "to": 51750, "type": "percent", "nearest": 1, "value": 5 },
          { "from": 51750, "to": 56991, "type": "percent", "nearest": 1, "value": 5.5 },
          { "from": 56991, "to": 63233, "type": "percent", "nearest": 1, "value": 6 },
          { "from": 63233, "to": 67519, "type": "percent", "nearest": 1, "value": 6.5 },
          { "from": 67519, "to": 76132, "type": "percent", "nearest": 1, "value": 7 },
          { "from": 76132, "to": 82320, "type": "percent", "nearest": 1, "value": 7.5 },
          { "from": 82320, "to": 0, "type": "percent", "nearest": 1, "value": 8 }
        ]
      },
      "sfss": {
        "label":"Student Financial Supplement Scheme",
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 54126, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 54126, "to": 66456, "type": "percent", "nearest": 1, "value": 2 },
          { "from": 66456, "to": 94331, "type": "percent", "nearest": 1, "value": 3 },
          { "from": 94331, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
        ]
      },
      "sfss_noTaxFree": {
        "incremental": 0,
        "brackets": [
          { "from": 0, "to": 35926, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 35926, "to": 48257, "type": "percent", "nearest": 1, "value": 2 },
          { "from": 48257, "to": 76132, "type": "percent", "nearest": 1, "value": 3 },
          { "from": 76132, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
        ]
      }
    },

  {
    "year": "2017", "current": "false",

    "tax": {
      "label": "Australian Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 18200, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 37000, "to": 87000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 87000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 355, "a": 0, "b": 0 },
        { "income": 410, "a": 0.19, "b": 67.4635 },
        { "income": 512, "a": 0.29, "b": 108.4923 },
        { "income": 711, "a": 0.21, "b": 67.4646 },
        { "income": 1282, "a": 0.3477, "b": 165.4435 },
        { "income": 1673, "a": 0.3450, "b": 161.9819 },
        { "income": 3461, "a": 0.3900, "b": 237.2704 },
        { "income": 0, "a": 0.49, "b": 583.4242 }
      ]

    },
    "taxNoFreeThreshold": {
      "label": "No Tax-Free Threshold",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 37000, "to": 87000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 87000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
      "payg": [
        { "income": 60, "a": 0.19, "b": 0.19 },
        { "income": 361, "a": 0.2332, "b": 2.6045 },
        { "income": 932, "a": 0.3477, "b": 44.0006 },
        { "income": 1323, "a": 0.3450, "b": 41.4841 },
        { "income": 3111, "a": 0.3900, "b": 101.0225 },
        { "income": 0, "a": 0.49, "b": 412.1764 }
      ]

    },
    "taxNonResident": {
      "label": "Non Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 87000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 87000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 1673, "a": 0.325, "b": 0.325 },
        { "income": 3461, "a": 0.37, "b": 75.2885 },
        { "income": 0, "a": 0.47, "b": 421.4423 }
      ]


    },
    "taxBackpacker": {
      "label": "Backpacker",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 15 },
        { "from": 37000, "to": 87000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 87000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

    },


    "extraWitholding": {
      "label": "Extra witholding",
      "incremental": 1,
      "weekly":{
        "brackets": [
          { "from": 0, "to": 724, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 724, "to": 1699, "type": "fixed", "nearest": 1, "value": 3 },
          { "from": 1700, "to": 3499, "type": "fixed", "nearest": 1, "value": 4 },
          { "from": 3500, "to": 0, "type": "fixed", "nearest": 1, "value": 9 },
        ],
      },
      "fortnightly":{
        "brackets": [
          { "from": 0, "to": 1399, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 1400, "to": 3399, "type": "fixed", "nearest": 1, "value": 12 },
          { "from": 3400, "to": 6899, "type": "fixed", "nearest": 1, "value": 17 },
          { "from": 6900, "to": 0, "type": "fixed", "nearest": 1, "value": 37 },
        ],
      }

    },




    "lito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "445" },
        { "from": 37000, "to": 66667, "type": "rate", "nearest": 1, "start": 445, "end": 0, "value": -1.5 },
        { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },


    "sapto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "single": {
        "brackets": [
          { "from": 0, "to": 32279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 32280, "to": 50119, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 50120, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "married": {
        "brackets": [
          { "from": 0, "to": 28974, "type": "fixed", "nearest": 1, "value": 1602 },
          { "from": 28974, "to": 41790, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 41790, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "illness": {
        "brackets": [
          { "from": 0, "to": 31279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 31279, "to": 47599, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 47599, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      }
    },



    "mawto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
    },

    "medicare": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 21335, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 21335, "to": 26668, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 26668, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareFamily": {
      "incremental": 1,
      "dependants": 3471,
      "brackets": [
        { "from": 0, "to": 41265, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 41265, "to": 51581, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 51582, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSenior": {
      "incremental": 1,
      "spouseOnly": 13886,
      "dependantOne": 17357,
      "dependants": 3471,
      "brackets": [
        { "from": 0, "to": 35418, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 35418, "to": 44272, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 44273, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSurcharge": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 90001, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 105001, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 140001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    "medicareSurchargeFamily": {
      "incremental": 0,
      "dependants": 1500,
      "brackets": [
        { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 180001, "to": 210000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 210001, "to": 280000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 280001, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },




    "other": [
      {
        "name": "Temporary Budget Repair levy",
        "incremental": 1,
        "brackets": [
          { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
          { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 2.0 }
        ]
      }

    ],
    "superannuation": {
      "incremental": 0,
      "concessionalCap": 30000,
      "nonConcesssionalCap": 180000,
      "nonConcessionalExcessTax": 0.47,
      "concessionalTax": 0.15,
      "brackets": [
        { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 9.5 }
      ]
    },
    "division293": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
        { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
      ]
    },
    "help": {
      "label": "HECS, HELP, VET, SSL, TSL",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 54869, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 54869, "to": 61120, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 61120, "to": 67369, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 67369, "to": 70910, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 70910, "to": 76223, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 76223, "to": 82551, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 82551, "to": 86895, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 86895, "to": 95627, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 95627, "to": 101900, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 101900, "to": 0, "type": "percent", "nearest": 1, "value": 8 }
      ]
    },
    "help_noTaxFree": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 36669, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 36669, "to": 42920, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 42920, "to": 49169, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 49169, "to": 52710, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 52710, "to": 58023, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 58023, "to": 64351, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 64351, "to": 68695, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 68695, "to": 77427, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 77427, "to": 83700, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 83700, "to": 0, "type": "percent", "nearest": 1, "value": 8 }
      ]
    },
    "sfss": {
      "label": "Student Financial Supplement Scheme",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 54869, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 54869, "to": 67368, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 67368, "to": 95626, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 95626, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
      ]
    },
    "sfss_noTaxFree": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 36669, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 36669, "to": 49169, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 49169, "to": 77427, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 77427, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
      ]
    }
  },

*/


  //                                                                                   
  //               .--~*teu.        .n~~%x.           oe       u+=~~~+u.               
  //              dF     988Nx    x88X   888.       .@88     z8F      `8N.             
  //             d888b   `8888>  X888X   8888L  ==*88888    d88L       98E             
  //             ?8888>  98888F X8888X   88888     88888    98888bu.. .@*              
  //              "**"  x88888~ 88888X   88888X    88888    "88888888NNu.              
  //                   d8888*`  88888X   88888X    88888     "*8888888888i             
  //                 z8**"`   : 88888X   88888f    88888     .zf""*8888888L            
  //               :?.....  ..F 48888X   88888     88888    d8F      ^%888E            
  //              <""888888888~  ?888X   8888"     88888    88>        `88~            
  //              8:  "888888*    "88X   88*`      88888    '%N.       d*"             
  //              ""    "**"`       ^"==="`     '**%%%%%%**    ^"====="`               
  //                                                                                   
  //                                                                                   
  //                                                                                   
  //   




  // {
  //   "year": "2018", "current": "false",

  //   "tax": {
  //     "label": "Australian Resident",
  //     "incremental": 1,
  //     "brackets": [
  //       { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
  //       { "from": 18200, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
  //       { "from": 37000, "to": 87000, "type": "percent", "nearest": 1, "value": 32.5 },
  //       { "from": 87000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
  //       { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

  //     "payg": [
  //       { "income": 355, "a": 0.0, "b": 0.0 },
  //       { "income": 416, "a": 0.19, "b": 67.4635 },
  //       { "income": 520, "a": 0.29, "b": 109.1077 },
  //       { "income": 711, "a": 0.21, "b": 67.4646 },
  //       { "income": 1282, "a": 0.3477, "b": 165.4435 },
  //       { "income": 1673, "a": 0.3450, "b": 161.9819 },
  //       { "income": 3461, "a": 0.39, "b": 237.2704 },
  //       { "income": 0, "a": 0.47, "b": 514.1935 }
  //     ]

  //   },
  //   "taxNoFreeThreshold": {
  //     "label": "No Tax-Free Threshold",
  //     "incremental": 1,
  //     "brackets": [
  //       { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
  //       { "from": 37000, "to": 87000, "type": "percent", "nearest": 1, "value": 32.5 },
  //       { "from": 87000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
  //       { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  //     "payg": [
  //       { "income": 66, "a": 0.19, "b": 0.19 },
  //       { "income": 361, "a": 0.2337, "b": 2.9035 },
  //       { "income": 932, "a": 0.3477, "b": 44.1189 },
  //       { "income": 1323, "a": 0.3450, "b": 41.6024 },
  //       { "income": 3111, "a": 0.3900, "b": 101.1408 },
  //       { "income": 0, "a": 0.47, "b": 350.0639 }
  //     ]

  //   },
  //   "taxNonResident": {
  //     "label": "Non Resident",
  //     "incremental": 1,
  //     "brackets": [
  //       { "from": 0, "to": 87000, "type": "percent", "nearest": 1, "value": 32.5 },
  //       { "from": 87000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
  //       { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

  //     "payg": [
  //       { "income": 1673, "a": 0.325, "b": 0.325 },
  //       { "income": 3461, "a": 0.37, "b": 75.2885 },
  //       { "income": 0, "a": 0.45, "b": 352.2115 }
  //     ]


  //   },
  //   "taxBackpacker": {
  //     "label": "Backpacker",
  //     "incremental": 1,
  //     "brackets": [
  //       { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 15 },
  //       { "from": 37000, "to": 87000, "type": "percent", "nearest": 1, "value": 32.5 },
  //       { "from": 87000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
  //       { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
  //   },

  //   "taxMedicareHalf": {
  //     "label": "Half Medicare exemption",
  //     "incremental": 1,
  //     "payg": [
  //       { "income": 335, "a": 0.0, "b": 0.0 },
  //       { "income": 711, "a": 0.19, "b": 67.4635 },
  //       { "income": 713, "a": 0.3277, "b": 165.4423 },
  //       { "income": 891, "a": 0.3777, "b": 201.1048 },
  //       { "income": 1282, "a": 0.3377, "b": 165.4425 },
  //       { "income": 1730, "a": 0.3350, "b": 161.9810 },
  //       { "income": 3461, "a": 0.3800, "b": 239.8565 },
  //       { "income": 0, "a": 0.4600, "b": 516.7887 },
  //     ]
  //   },

  //   "taxMedicareFull": {
  //     "label": "Full Medicare exemption",
  //     "incremental": 1,
  //     "payg": [
  //       { "income": 335, "a": 0.0, "b": 0.0 },
  //       { "income": 711, "a": 0.19, "b": 67.4635 },
  //       { "income": 1282, "a": 0.3277, "b": 165.4423 },
  //       { "income": 1730, "a": 0.3250, "b": 161.9808 },
  //       { "income": 3461, "a": 0.3700, "b": 239.8654 },
  //       { "income": 0, "a": 0.4500, "b": 516.7885 },
  //     ]
  //   },


  //   "extraWitholding": {
  //     "label": "Extra witholding",
  //     "incremental": 1,
  //     "weekly": {
  //       "brackets": [
  //         { "from": 0, "to": 724, "type": "fixed", "nearest": 1, "value": 0 },
  //         { "from": 724, "to": 1700, "type": "fixed", "nearest": 1, "value": 3 },
  //         { "from": 1700, "to": 3500, "type": "fixed", "nearest": 1, "value": 4 },
  //         { "from": 3500, "to": 0, "type": "fixed", "nearest": 1, "value": 9 },
  //       ],
  //     },
  //     "fortnightly": {
  //       "brackets": [
  //         { "from": 0, "to": 1400, "type": "fixed", "nearest": 1, "value": 0 },
  //         { "from": 1400, "to": 3400, "type": "fixed", "nearest": 1, "value": 12 },
  //         { "from": 3400, "to": 6900, "type": "fixed", "nearest": 1, "value": 17 },
  //         { "from": 6900, "to": 0, "type": "fixed", "nearest": 1, "value": 37 },
  //       ],
  //     }

  //   },

  //   "fbt": {
  //     "threshold": 2000,
  //     "rate": 47,
  //     "type1": 2.0802,
  //     "type2": 1.8868,
  //   },


  //   "lito": {
  //     "incremental": 0,
  //     "paygLoading": 1.0,
  //     "brackets": [
  //       { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "445" },
  //       { "from": 37000, "to": 66667, "type": "rate", "nearest": 1, "start": 445, "end": 0, "value": -1.5 },
  //       { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
  //     ]
  //   },



  //   "sapto": {
  //     "incremental": 1,
  //     "paygLoading": 1.0,
  //     "single": {
  //       "brackets": [
  //         { "from": 0, "to": 32279, "type": "fixed", "nearest": 1, "value": 2230 },
  //         { "from": 32279, "to": 50120, "type": "percent", "nearest": 1, "value": -12.5 },
  //         { "from": 50120, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
  //       ]
  //     },
  //     "married": {
  //       "brackets": [
  //         { "from": 0, "to": 28974, "type": "fixed", "nearest": 1, "value": 1602 },
  //         { "from": 28974, "to": 41790, "type": "percent", "nearest": 1, "value": -12.5 },
  //         { "from": 41790, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
  //       ]
  //     },
  //     "illness": {
  //       "brackets": [
  //         { "from": 0, "to": 31279, "type": "fixed", "nearest": 1, "value": 2040 },
  //         { "from": 31279, "to": 47599, "type": "percent", "nearest": 1, "value": -12.5 },
  //         { "from": 47599, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
  //       ]
  //     }
  //   },



  //   "mawto": {
  //     "incremental": 1,
  //     "paygLoading": 1.0,
  //     "brackets": [
  //       { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
  //   },
  //   /*
  //       "medicare": {
  //         "incremental": 1,
  //         "brackets": [
  //           { "from": 0, "to": 21980, "type": "percent", "nearest": 1, "value": 0 },
  //           { "from": 21980, "to": 27475, "type": "percent", "nearest": 1, "value": 10.0 },
  //           { "from": 27475, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
  //         ]
  //       },

  //       "medicareFamily": {
  //         "incremental": 1,
  //         "dependants": 3471,
  //         "brackets": [
  //           { "from": 0, "to": 41265, "type": "percent", "nearest": 1, "value": 0 },
  //           { "from": 41265, "to": 51582, "type": "percent", "nearest": 1, "value": 10.0 },
  //           { "from": 51582, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
  //         ]
  //       },

  //       "medicareSenior": {
  //         "incremental": 1,
  //         "spouseOnly": 13886,
  //         "dependantOne": 17357,
  //         "dependants": 3471,
  //         "brackets": [
  //           { "from": 0, "to": 35418, "type": "percent", "nearest": 1, "value": 0 },
  //           { "from": 35418, "to": 44273, "type": "percent", "nearest": 1, "value": 10.0 },
  //           { "from": 44273, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
  //         ]
  //       },
  //   */
  //   "medicare": {
  //     // 2017 - 2018
  //     // Income reduction threshold = threshold*0.1 /  0.008
  //     "incremental": 1,
  //     "brackets": [
  //       { "from": 0, "to": 21980, "type": "percent", "nearest": 1, "value": 0 },
  //       { "from": 21980, "to": 27475, "type": "percent", "nearest": 1, "value": 10.0 },
  //       { "from": 27475, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
  //     ],


  //   },

  //   "medicareFamily": {
  //     "incremental": 1,
  //     "dependants": 3406,
  //     "brackets": [
  //       { "from": 0, "to": 37089, "type": "percent", "nearest": 1, "value": 0 },
  //       { "from": 37089, "to": 46361, "type": "percent", "nearest": 1, "value": 10.0 },
  //       { "from": 46361, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
  //     ]
  //   },

  //   "medicareSenior": {
  //     "incremental": 1,
  //     "brackets": [
  //       { "from": 0, "to": 34758, "type": "percent", "nearest": 1, "value": 0 },
  //       { "from": 34758, "to": 43448, "type": "percent", "nearest": 1, "value": 10.0 },
  //       { "from": 43448, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
  //     ]
  //   },

  //   "medicareSeniorFamily": {
  //     "incremental": 1,
  //     "dependants": 3406,
  //     "brackets": [
  //       { "from": 0, "to": 48385, "type": "percent", "nearest": 1, "value": 0 },
  //       { "from": 48385, "to": 60481, "type": "percent", "nearest": 1, "value": 10.0 },
  //       { "from": 60481, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
  //     ]
  //   },

  //   "medicareAdjustment": {
  //     "earningThreshold": { "scale2": 422, "scale6": 713 },
  //     "shadeInThreshold": { "scale2": 528, "scale6": 891 },
  //     "annualThreshold": { "scale2": 37089, "scale6": 37089 },
  //     "additioalChild": { "scale2": 3406, "scale6": 3406 },
  //     "shadeOutMultiplier": { "scale2": 0.1, "scale6": 0.05 },
  //     "shadeOutDivisor": { "scale2": 0.08, "scale6": 0.04 },
  //     "weeklyAdjustment": { "scale2": 422.69, "scale6": 713.25 },
  //     "medicareLevy": { "scale2": 0.02, "scale6": 0.01 },
  //   },




  //   "medicareSurcharge": {
  //     "incremental": 0,
  //     "brackets": [
  //       { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
  //       { "from": 90000, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
  //       { "from": 105000, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
  //       { "from": 140000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
  //     ]
  //   },

  //   "medicareSurchargeFamily": {
  //     "incremental": 0,
  //     "dependants": 1500,
  //     "brackets": [
  //       { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
  //       { "from": 180000, "to": 210000, "type": "percent", "nearest": 1, "value": 1.0 },
  //       { "from": 210000, "to": 280000, "type": "percent", "nearest": 1, "value": 1.25 },
  //       { "from": 280000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
  //     ]
  //   },


  //   "other": [
  //   ],

  //   "superannuation": {
  //     "incremental": 0,
  //     "maximumContributionBaseQuarter": 52760,
  //     "concessionalCap": 25000,
  //     "nonConcesssionalCap": 100000,
  //     "nonConcessionalExcessTax": 0.47,
  //     "concessionalTax": 0.15,
  //     "rate": 9.5,
  //     "brackets": [
  //       { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 9.5 }
  //     ]
  //   },


  //   "superannuationCocontribution": {
  //     "minIncome": 38564,
  //     "maxIncome": 53564,
  //     "maxEntitlement": 500,
  //     "minContribution": 20,
  //     "reductionFactor": 0.03333,
  //     "contributionRate": 0.5,
  //   },

  //   "superannuationLISTO": {
  //     "maxIncome": 37000,
  //     "maxEntitlement": 500,
  //     "minContribution": 10,
  //     "contributionRate": 0.15,
  //   },

  //   "division293": {
  //     "incremental": 1,
  //     "threshold": 250000,
  //     "rate": 15,
  //     "brackets": [
  //       { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
  //       { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
  //     ]
  //   },


  //   "smallBusinessOffset": {
  //     "label": "Small Business Offset",
  //     "threshold": 5000000,
  //     "cap": 1000,
  //     "rate": 15,
  //     "brackets": [
  //       { "from": 0, "to": 5000000, "type": "percent", "nearest": 1, "value": 8.0 },
  //       { "from": 5000000, "to": 0, "type": "percent", "nearest": 1, "value": 0.0 }
  //     ]
  //   },

  //   "help": {
  //     "label": "HECS, HELP, VET, SSL, TSL",
  //     "incremental": 0,
  //     "brackets": [
  //       { "from": 0, "to": 55873, "type": "fixed", "nearest": 1, "value": 0 },
  //       { "from": 55873, "to": 62238, "type": "percent", "nearest": 1, "value": 4 },
  //       { "from": 62238, "to": 68602, "type": "percent", "nearest": 1, "value": 4.5 },
  //       { "from": 68602, "to": 72207, "type": "percent", "nearest": 1, "value": 5.0 },
  //       { "from": 72207, "to": 77618, "type": "percent", "nearest": 1, "value": 5.5 },
  //       { "from": 77618, "to": 84062, "type": "percent", "nearest": 1, "value": 6.0 },
  //       { "from": 84062, "to": 88486, "type": "percent", "nearest": 1, "value": 6.5 },
  //       { "from": 88486, "to": 97377, "type": "percent", "nearest": 1, "value": 7.0 },
  //       { "from": 97377, "to": 103765, "type": "percent", "nearest": 1, "value": 7.5 },
  //       { "from": 103765, "to": 0, "type": "percent", "nearest": 1, "value": 8.0 }
  //     ]
  //   },
  //   "help_noTaxFree": {
  //     "incremental": 0,
  //     "brackets": [
  //       { "from": 0, "to": 36674, "type": "fixed", "nearest": 1, "value": 0 },
  //       { "from": 36674, "to": 44038, "type": "percent", "nearest": 1, "value": 4 },
  //       { "from": 44038, "to": 50402, "type": "percent", "nearest": 1, "value": 4.5 },
  //       { "from": 50402, "to": 54007, "type": "percent", "nearest": 1, "value": 5 },
  //       { "from": 54007, "to": 59418, "type": "percent", "nearest": 1, "value": 5.5 },
  //       { "from": 59418, "to": 65862, "type": "percent", "nearest": 1, "value": 6 },
  //       { "from": 65862, "to": 70286, "type": "percent", "nearest": 1, "value": 6.5 },
  //       { "from": 70286, "to": 79177, "type": "percent", "nearest": 1, "value": 7 },
  //       { "from": 79177, "to": 85565, "type": "percent", "nearest": 1, "value": 7.5 },
  //       { "from": 85565, "to": 0, "type": "percent", "nearest": 1, "value": 8 }
  //     ]
  //   },
  //   "sfss": {
  //     "label": "Student Financial Supplement Scheme",
  //     "incremental": 0,
  //     "brackets": [
  //       { "from": 0, "to": 55874, "type": "fixed", "nearest": 1, "value": 0 },
  //       { "from": 55874, "to": 68602, "type": "percent", "nearest": 1, "value": 2 },
  //       { "from": 68602, "to": 97377, "type": "percent", "nearest": 1, "value": 3 },
  //       { "from": 97377, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
  //     ]
  //   },
  //   "sfss_noTaxFree": {
  //     "incremental": 0,
  //     "brackets": [
  //       { "from": 0, "to": 37674, "type": "fixed", "nearest": 1, "value": 0 },
  //       { "from": 37674, "to": 50402, "type": "percent", "nearest": 1, "value": 2 },
  //       { "from": 50402, "to": 79177, "type": "percent", "nearest": 1, "value": 3 },
  //       { "from": 79177, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
  //     ]
  //   }
  // },






  //                                                                                   
  //               .--~*teu.        .n~~%x.           oe                               
  //              dF     988Nx    x88X   888.       .@88      .xn!~%x.                 
  //             d888b   `8888>  X888X   8888L  ==*88888     x888   888.               
  //             ?8888>  98888F X8888X   88888     88888    X8888   8888:              
  //              "**"  x88888~ 88888X   88888X    88888    88888   X8888              
  //                   d8888*`  88888X   88888X    88888    88888   88888>             
  //                 z8**"`   : 88888X   88888f    88888    `8888  :88888X             
  //               :?.....  ..F 48888X   88888     88888      `"**~ 88888>             
  //              <""888888888~  ?888X   8888"     88888     .xx.   88888              
  //              8:  "888888*    "88X   88*`      88888    '8888>  8888~              
  //              ""    "**"`       ^"==="`     '**%%%%%%**  888"  :88%                
  //                                                          ^"===""                  
  //                                                                                   
  //         



  {
    "year": "2019", "current": "false",

    "tax": {
      "label": "Australian Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 18200, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 37000, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 355, "a": 0.0, "b": 0.0 },
        { "income": 422, "a": 0.19, "b": 67.4635 },
        { "income": 528, "a": 0.29, "b": 109.7327 },
        { "income": 711, "a": 0.21, "b": 67.4635 },
        { "income": 1282, "a": 0.3477, "b": 165.4423 },
        { "income": 1730, "a": 0.3450, "b": 161.9808 },
        { "income": 3461, "a": 0.39, "b": 239.8654 },
        { "income": 0, "a": 0.47, "b": 516.7885 }
      ]

    },
    "taxNoFreeThreshold": {
      "label": "No Tax-Free Threshold",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 37000, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 72, "a": 0.19, "b": 0.19 },
        { "income": 361, "a": 0.2342, "b": 3.2130 },
        { "income": 932, "a": 0.3477, "b": 44.2476 },
        { "income": 1380, "a": 0.345, "b": 41.7311 },
        { "income": 3111, "a": 0.39, "b": 103.8657 },
        { "income": 0, "a": 0.47, "b": 352.7888 }
      ]

    },
    "taxNonResident": {
      "label": "Non Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 1730, "a": 0.325, "b": 0.325 },
        { "income": 3461, "a": 0.37, "b": 77.8846 },
        { "income": 0, "a": 0.45, "b": 354.8077 }
      ]

    },
    "taxBackpacker": {
      "label": "Backpacker",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 15 },
        { "from": 37000, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
    },

    "taxMedicareHalf": {
      "label": "Half Medicare exemption",
      "incremental": 1,
      "payg": [
        { "income": 335, "a": 0.0, "b": 0.0 },
        { "income": 711, "a": 0.19, "b": 67.4635 },
        { "income": 713, "a": 0.3277, "b": 165.4423 },
        { "income": 891, "a": 0.3777, "b": 201.1048 },
        { "income": 1282, "a": 0.3377, "b": 165.4425 },
        { "income": 1730, "a": 0.3350, "b": 161.9810 },
        { "income": 3461, "a": 0.3800, "b": 239.8565 },
        { "income": 0, "a": 0.4600, "b": 516.7887 },
      ]
    },

    "taxMedicareFull": {
      "label": "Full Medicare exemption",
      "incremental": 1,
      "payg": [
        { "income": 335, "a": 0.0, "b": 0.0 },
        { "income": 711, "a": 0.19, "b": 67.4635 },
        { "income": 1282, "a": 0.3277, "b": 165.4423 },
        { "income": 1730, "a": 0.3250, "b": 161.9808 },
        { "income": 3461, "a": 0.3700, "b": 239.8654 },
        { "income": 0, "a": 0.4500, "b": 516.7885 },
      ]
    },

    "extraWitholding": {
      "label": "Extra witholding",
      "incremental": 1,
      "weekly": {
        "brackets": [
          { "from": 0, "to": 724, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 724, "to": 1700, "type": "fixed", "nearest": 1, "value": 3 },
          { "from": 1700, "to": 3500, "type": "fixed", "nearest": 1, "value": 4 },
          { "from": 3500, "to": 0, "type": "fixed", "nearest": 1, "value": 9 },
        ],
      },
      "fortnightly": {
        "brackets": [
          { "from": 0, "to": 1400, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 1400, "to": 3400, "type": "fixed", "nearest": 1, "value": 12 },
          { "from": 3400, "to": 6900, "type": "fixed", "nearest": 1, "value": 17 },
          { "from": 6900, "to": 0, "type": "fixed", "nearest": 1, "value": 37 },
        ],
      }

    },

    "fbt": {
      "threshold": 2000,
      "rate": 47,
      "type1": 2.0802,
      "type2": 1.8868,
    },


    "lito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "445" },
        { "from": 37000, "to": 66667, "type": "rate", "nearest": 1, "start": 445, "end": 0, "value": -1.5 },
        { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "lamito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": 255 },
        { "from": 37000, "to": 48000, "type": "rate", "nearest": 1, "start": 255, "end": 1080, "value": 7.5 },
        { "from": 48000, "to": 90000, "type": "fixed", "nearest": 1, "value": 1080 },
        { "from": 90000, "to": 126000, "type": "rate", "nearest": 1, "start": 1080, "end": 0, "value": -3.0 },
        { "from": 126000, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },



    "sapto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "single": {
        "brackets": [
          { "from": 0, "to": 32279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 32279, "to": 50120, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 50120, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "married": {
        "brackets": [
          { "from": 0, "to": 28974, "type": "fixed", "nearest": 1, "value": 1602 },
          { "from": 28974, "to": 41790, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 41790, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "illness": {
        "brackets": [
          { "from": 0, "to": 31279, "type": "fixed", "nearest": 1, "value": 2040 },
          { "from": 31279, "to": 47599, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 47599, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      }
    },


    "mawto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
    },

    //// 2018 - 2019
    "medicare": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 22398, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 22398, "to": 27998, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 27998, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ],
    },


    "medicareFamily": {
      "incremental": 1,
      "dependants": 3471,
      "brackets": [
        { "from": 0, "to": 37794, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 37794, "to": 47243, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 47243, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSenior": {
      "incremental": 1,
      "dependants": 3471,
      "brackets": [
        { "from": 0, "to": 35418, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 35418, "to": 44273, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 44273, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },
    "medicareSeniorFamily": {
      "incremental": 1,
      "dependants": 3471,
      "brackets": [
        { "from": 0, "to": 49304, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 49304, "to": 61630, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 61630, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },


    "medicareAdjustment": {
      "earningThreshold": { "scale2": 422, "scale6": 713 },
      "shadeInThreshold": { "scale2": 528, "scale6": 891 },
      "annualThreshold": { "scale2": 37089, "scale6": 37089 },
      "additioalChild": { "scale2": 3406, "scale6": 3406 },
      "shadeOutMultiplier": { "scale2": 0.1, "scale6": 0.05 },
      "shadeOutDivisor": { "scale2": 0.08, "scale6": 0.04 },
      "weeklyAdjustment": { "scale2": 422.69, "scale6": 713.25 },
      "medicareLevy": { "scale2": 0.02, "scale6": 0.01 },
    },


    "medicareSurcharge": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 90000, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 105000, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 140000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    "medicareSurchargeFamily": {
      "incremental": 0,
      "dependants": 1500,
      "exemption": 26000,
      "brackets": [
        { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 180000, "to": 210000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 210000, "to": 280000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 280000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },


    "other": [
    ],
    "superannuation": {
      "incremental": 0,
      "maximumContributionBaseQuarter": 54030,
      "concessionalCap": 25000,
      "nonConcesssionalCap": 100000,
      "nonConcessionalExcessTax": 0.47,
      "concessionalTax": 0.15,
      "rate": 9.5,
      "brackets": [
        { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 9.5 }
      ]
    },

    "division293": {
      "incremental": 1,
      "threshold": 250000,
      "rate": 15,
      "brackets": [
        { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
        { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
      ]
    },
    "help": {
      "label": "HECS, HELP, VET, SSL, TSL",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 51957, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 51957, "to": 57729, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 57729, "to": 64306, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 64306, "to": 70881, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 70881, "to": 74607, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 74607, "to": 80197, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 80197, "to": 86855, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 86855, "to": 91425, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 91425, "to": 100613, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 100613, "to": 107213, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 107213, "to": 0, "type": "percent", "nearest": 1, "value": 8.0 }
      ],
    },
    "help_noTaxFree": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 33757, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 33757, "to": 39529, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 39529, "to": 46106, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 46106, "to": 52681, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 52681, "to": 56407, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 56407, "to": 61997, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 61997, "to": 68655, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 68655, "to": 73225, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 73225, "to": 82413, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 82413, "to": 89013, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 89013, "to": 0, "type": "percent", "nearest": 1, "value": 8.0 }
      ],

    },
    "sfss": {
      "label": "Student Financial Supplement Scheme",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 51957, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 51958, "to": 64306, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 64306, "to": 91425, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 91425, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
      ]
    },
    "sfss_noTaxFree": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 33757, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 33757, "to": 46106, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 46106, "to": 72225, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 72225, "to": 0, "type": "percent", "nearest": 1, "value": 4 }
      ]
    }
  },







  //                                                                                   
  //                                                                                   
  //             .--~*teu.        .n~~%x.       .--~*teu.        .n~~%x.               
  //            dF     988Nx    x88X   888.    dF     988Nx    x88X   888.             
  //           d888b   `8888>  X888X   8888L  d888b   `8888>  X888X   8888L            
  //           ?8888>  98888F X8888X   88888  ?8888>  98888F X8888X   88888            
  //            "**"  x88888~ 88888X   88888X  "**"  x88888~ 88888X   88888X           
  //                 d8888*`  88888X   88888X       d8888*`  88888X   88888X           
  //               z8**"`   : 88888X   88888f     z8**"`   : 88888X   88888f           
  //             :?.....  ..F 48888X   88888    :?.....  ..F 48888X   88888            
  //            <""888888888~  ?888X   8888"   <""888888888~  ?888X   8888"            
  //            8:  "888888*    "88X   88*`    8:  "888888*    "88X   88*`             
  //            ""    "**"`       ^"==="`      ""    "**"`       ^"==="`               
  //                                                                                   
  //                                                                                   
  //       


  {
    "year": "2020", "current": "false",

    "tax": {
      "label": "Australian Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 18200, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 37000, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 355, "a": 0.0, "b": 0.0 },
        { "income": 422, "a": 0.19, "b": 67.4635 },
        { "income": 528, "a": 0.29, "b": 109.7327 },
        { "income": 711, "a": 0.21, "b": 67.4635 },
        { "income": 1282, "a": 0.3477, "b": 165.4423 },
        { "income": 1730, "a": 0.3450, "b": 161.9808 },
        { "income": 3461, "a": 0.39, "b": 239.8654 },
        { "income": 0, "a": 0.47, "b": 516.7885 }
      ]

    },
    "taxNoFreeThreshold": {
      "label": "No Tax-Free Threshold",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 37000, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 72, "a": 0.19, "b": 0.19 },
        { "income": 361, "a": 0.2342, "b": 3.2130 },
        { "income": 932, "a": 0.3477, "b": 44.2476 },
        { "income": 1380, "a": 0.345, "b": 41.7311 },
        { "income": 3111, "a": 0.39, "b": 103.8657 },
        { "income": 0, "a": 0.47, "b": 352.7888 }
      ]

    },
    "taxNonResident": {
      "label": "Non Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 1730, "a": 0.325, "b": 0.325 },
        { "income": 3461, "a": 0.37, "b": 77.8846 },
        { "income": 0, "a": 0.45, "b": 354.8077 }
      ]

    },
    "taxBackpacker": {
      "label": "Backpacker",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 37000, "type": "percent", "nearest": 1, "value": 15 },
        { "from": 37000, "to": 90000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 90000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
    },

    "taxMedicareHalf": {
      "label": "Half Medicare exemption",
      "incremental": 1,
      "payg": [
        { "income": 335, "a": 0.0, "b": 0.0 },
        { "income": 711, "a": 0.19, "b": 67.4635 },
        { "income": 713, "a": 0.3277, "b": 165.4423 },
        { "income": 891, "a": 0.3777, "b": 201.1048 },
        { "income": 1282, "a": 0.3377, "b": 165.4425 },
        { "income": 1730, "a": 0.3350, "b": 161.9810 },
        { "income": 3461, "a": 0.3800, "b": 239.8565 },
        { "income": 0, "a": 0.4600, "b": 516.7887 },
      ]
    },

    "taxMedicareFull": {
      "label": "Full Medicare exemption",
      "incremental": 1,
      "payg": [
        { "income": 335, "a": 0.0, "b": 0.0 },
        { "income": 711, "a": 0.19, "b": 67.4635 },
        { "income": 1282, "a": 0.3277, "b": 165.4423 },
        { "income": 1730, "a": 0.3250, "b": 161.9808 },
        { "income": 3461, "a": 0.3700, "b": 239.8654 },
        { "income": 0, "a": 0.4500, "b": 516.7885 },
      ]
    },

    "extraWitholding": {
      "label": "Extra witholding",
      "incremental": 1,
      "weekly": {
        "brackets": [
          { "from": 0, "to": 724, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 724, "to": 1700, "type": "fixed", "nearest": 1, "value": 3 },
          { "from": 1700, "to": 3500, "type": "fixed", "nearest": 1, "value": 4 },
          { "from": 3500, "to": 0, "type": "fixed", "nearest": 1, "value": 9 },
        ],
      },
      "fortnightly": {
        "brackets": [
          { "from": 0, "to": 1400, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 1400, "to": 3400, "type": "fixed", "nearest": 1, "value": 12 },
          { "from": 3400, "to": 6900, "type": "fixed", "nearest": 1, "value": 17 },
          { "from": 6900, "to": 0, "type": "fixed", "nearest": 1, "value": 37 },
        ],
      }

    },

    "fbt": {
      "threshold": 2000,
      "rate": 47,
      "type1": 2.0802,
      "type2": 1.8868,
    },


    "lito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": "445" },
        { "from": 37000, "to": 66667, "type": "rate", "nearest": 1, "start": 445, "end": 0, "value": -1.5 },
        { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "lamito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": 255 },
        { "from": 37000, "to": 48000, "type": "rate", "nearest": 1, "start": 255, "end": 1080, "value": 7.5 },
        { "from": 48000, "to": 90000, "type": "fixed", "nearest": 1, "value": 1080 },
        { "from": 90000, "to": 126000, "type": "rate", "nearest": 1, "start": 1080, "end": 0, "value": -3.0 },
        { "from": 126000, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "sapto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "single": {
        "brackets": [
          { "from": 0, "to": 32279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 32279, "to": 50120, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 50120, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "married": {
        "brackets": [
          { "from": 0, "to": 28974, "type": "fixed", "nearest": 1, "value": 1602 },
          { "from": 28974, "to": 41790, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 41790, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "illness": {
        "brackets": [
          { "from": 0, "to": 31279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 31279, "to": 47599, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 47599, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      }
    },



    "mawto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
    },


    // 2019 - 2020
    // https://atotaxrates.info/individual-tax-rates-resident/medicare-levy/#:~:text=2017%2D18%20Medicare%20Levy%20Income%20Thresholds&text=The%20family%20threshold%20will%20be%20increased%20from%20%2436%2C541%20to%20%2437%2C089,increased%20from%20%2447%2C670%20to%20%2448%2C385.

    "medicare": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 22801, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 22801, "to": 28501, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 28501, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ],
    },

    "medicareFamily": {
      "incremental": 1,
      "dependants": 3533,
      "brackets": [
        { "from": 0, "to": 38474, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 38474, "to": 48093, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 48093, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSenior": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 36056, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 36056, "to": 45070, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 45070, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSeniorFamily": {
      "incremental": 1,
      "dependants": 3533,
      "brackets": [
        { "from": 0, "to": 50191, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 50191, "to": 62739, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 62739, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareAdjustment": {
      "earningThreshold": { "scale2": 422, "scale6": 713 },
      "shadeInThreshold": { "scale2": 528, "scale6": 891 },
      "annualThreshold": { "scale2": 37089, "scale6": 37089 },
      "additioalChild": { "scale2": 3406, "scale6": 3406 },
      "shadeOutMultiplier": { "scale2": 0.1, "scale6": 0.05 },
      "shadeOutDivisor": { "scale2": 0.08, "scale6": 0.04 },
      "weeklyAdjustment": { "scale2": 422.69, "scale6": 713.25 },
      "medicareLevy": { "scale2": 0.02, "scale6": 0.01 },
    },


    "medicareSurcharge": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 90000, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 105000, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 140000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    "medicareSurchargeFamily": {
      "incremental": 0,
      "dependants": 1500,
      "exemption": 26000,
      "brackets": [
        { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 180000, "to": 210000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 210000, "to": 280000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 280000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },


    "other": [
    ],
    "superannuation": {
      "incremental": 0,
      "maximumContributionBaseQuarter": 55270,
      "concessionalCap": 25000,
      "nonConcesssionalCap": 100000,
      "nonConcessionalExcessTax": 0.47,
      "concessionalTax": 0.15,
      "rate": 9.5,
      "brackets": [
        { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 9.5 }
      ]
    },


    "superannuationCocontribution": {
      "minIncome": 38564,
      "maxIncome": 53564,
      "maxEntitlement": 500,
      "minContribution": 20,
      "reductionFactor": 0.03333,
      "contributionRate": 0.5,
    },

    "superannuationLISTO": {
      "maxIncome": 37000,
      "maxEntitlement": 500,
      "minContribution": 10,
      "contributionRate": 0.15,
    },

    "superannuationCarryForward": {
      "cap": 25000,
    },

    "superannuationSpouseTaxOffset": {
      "spouseIncomeCap": 40000,
      "rate": 0.18,
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": 3000 },
        { "from": 37000, "to": 40000, "type": "rate", "nearest": 1, "start": 3000, "end": 0, "value": -100 },
        { "from": 40000, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },


    "division293": {
      "incremental": 1,
      "threshold": 250000,
      "rate": 15,
      "brackets": [
        { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
        { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
      ]
    },
    "help": {
      "label": "HECS, HELP, VET, SSL, TSL",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 45881, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 45881, "to": 52974, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 52974, "to": 56152, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 56152, "to": 59522, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 59522, "to": 63093, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 63093, "to": 66878, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 66878, "to": 70891, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 70891, "to": 75145, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 75145, "to": 79653, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 79653, "to": 84433, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 84433, "to": 89499, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 89499, "to": 94869, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 94869, "to": 100561, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 100561, "to": 106594, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 106594, "to": 112990, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 112990, "to": 119770, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 119770, "to": 126956, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 126956, "to": 134573, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 134573, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },

    "help_noTaxFree": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 27680, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 27680, "to": 34774, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 34774, "to": 37952, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 37952, "to": 41322, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 41322, "to": 44893, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 44893, "to": 48678, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 48678, "to": 52691, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 52691, "to": 56945, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 56945, "to": 61453, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 61453, "to": 66233, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 66233, "to": 71299, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 71299, "to": 76669, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 76669, "to": 82361, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 82361, "to": 88394, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 88394, "to": 94790, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 94790, "to": 101570, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 101570, "to": 108756, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 108756, "to": 116373, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 116373, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },
    "sfss": {
      "label": "SFSS",
      "brackets": [
        { "from": 0, "to": 45881, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 45881, "to": 52974, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 52974, "to": 56152, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 56152, "to": 59522, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 59522, "to": 63093, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 63093, "to": 66878, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 66878, "to": 70891, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 70891, "to": 75145, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 75145, "to": 79653, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 79653, "to": 84433, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 84433, "to": 89499, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 89499, "to": 94869, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 94869, "to": 100561, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 100561, "to": 106594, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 106594, "to": 112990, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 112990, "to": 119770, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 119770, "to": 126956, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 126956, "to": 134573, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 134573, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },
    "sfss_noTaxFree": {
      "label": "SFSS - No tax free threshold",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 27680, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 27680, "to": 34774, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 34774, "to": 37952, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 37952, "to": 41322, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 41322, "to": 44893, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 44893, "to": 48678, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 48678, "to": 52691, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 52691, "to": 56945, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 56945, "to": 61453, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 61453, "to": 66233, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 66233, "to": 71299, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 71299, "to": 76669, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 76669, "to": 82361, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 82361, "to": 88394, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 88394, "to": 94790, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 94790, "to": 101570, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 101570, "to": 108756, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 108756, "to": 116373, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 116373, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    }
  },







  // -------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------


  //                                                                                   
  //                                                                                   
  //               .--~*teu.        .n~~%x.       .--~*teu.          oe                
  //              dF     988Nx    x88X   888.    dF     988Nx      .@88                
  //             d888b   `8888>  X888X   8888L  d888b   `8888> ==*88888                
  //             ?8888>  98888F X8888X   88888  ?8888>  98888F    88888                
  //              "**"  x88888~ 88888X   88888X  "**"  x88888~    88888                
  //                   d8888*`  88888X   88888X       d8888*`     88888                
  //                 z8**"`   : 88888X   88888f     z8**"`   :    88888                
  //               :?.....  ..F 48888X   88888    :?.....  ..F    88888                
  //              <""888888888~  ?888X   8888"   <""888888888~    88888                
  //              8:  "888888*    "88X   88*`    8:  "888888*     88888                
  //              ""    "**"`       ^"==="`      ""    "**"`   '**%%%%%%**             
  //                                                                                   
  //                                                                                   
  //                     


  // -------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------





  {
    "year": "2021", "current": "false",

    "tax": {
      "label": "Australian Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 18200, "to": 45000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 45000, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 359, "a": 0, "b": 0 },
        { "income": 438, "a": 0.19, "b": 68.3462 },
        { "income": 548, "a": 0.29, "b": 112.1942 },
        { "income": 721, "a": 0.21, "b": 68.3465 },
        { "income": 865, "a": 0.219, "b": 74.8369 },
        { "income": 1282, "a": 0.3477, "b": 186.2119 },
        { "income": 2307, "a": 0.345, "b": 182.7504 },
        { "income": 3461, "a": 0.39, "b": 286.5965 },
        { "income": 0, "a": 0.47, "b": 563.5196 }
      ]

    },
    "taxNoFreeThreshold": {
      "label": "No Tax-Free Threshold",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 45000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 45000, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 88, "a": 0.19, "b": 0.19 },
        { "income": 371, "a": 0.2348, "b": 3.9639 },
        { "income": 515, "a": 0.219, "b": -1.9003 },
        { "income": 932, "a": 0.3477, "b": 64.4297 },
        { "income": 1957, "a": 0.345, "b": 61.9132 },
        { "income": 3111, "a": 0.39, "b": 150.0093 },
        { "income": 0, "a": 0.47, "b": 398.9324 }
      ]

    },
    "taxNonResident": {
      "label": "Non Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 2307, "a": 0.325, "b": 0.325 },
        { "income": 3461, "a": 0.37, "b": 103.8462 },
        { "income": 0, "a": 0.45, "b": 380.7692 }
      ]



    },
    "taxBackpacker": {
      "label": "Backpacker",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 45000, "type": "percent", "nearest": 1, "value": 15 },
        { "from": 45000, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
    },

    "taxMedicareHalf": {
      "label": "Half Medicare exemption",
      "incremental": 1,
      "payg": [
        { "income": 359, "a": 0.0, "b": 0.0 },
        { "income": 721, "a": 0.19, "b": 68.3462 },
        { "income": 739, "a": 0.199, "b": 74.8365 },
        { "income": 865, "a": 0.249, "b": 111.8308 },
        { "income": 924, "a": 0.3777, "b": 223.2058 },
        { "income": 1282, "a": 0.3377, "b": 186.2119 },
        { "income": 2307, "a": 0.335, "b": 182.7504 },
        { "income": 3461, "a": 0.38, "b": 286.5965 },
        { "income": 0, "a": 0.4600, "b": 563.5196 },
      ]
    },

    "taxMedicareFull": {
      "label": "Full Medicare exemption",
      "incremental": 1,
      "payg": [
        { "income": 359, "a": 0.0, "b": 0.0 },
        { "income": 721, "a": 0.19, "b": 68.3462 },
        { "income": 865, "a": 0.199, "b": 74.8365 },
        { "income": 1282, "a": 0.3277, "b": 186.2115 },
        { "income": 2307, "a": 0.3250, "b": 182.750 },
        { "income": 3461, "a": 0.37, "b": 286.5962 },
        { "income": 0, "a": 0.4500, "b": 563.5192 },
      ]
    },

    "extraWitholding": {
      "label": "Extra witholding",
      "incremental": 1,
      "weekly": {
        "brackets": [
          { "from": 0, "to": 724, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 724, "to": 1700, "type": "fixed", "nearest": 1, "value": 3 },
          { "from": 1700, "to": 3500, "type": "fixed", "nearest": 1, "value": 4 },
          { "from": 3500, "to": 0, "type": "fixed", "nearest": 1, "value": 9 },
        ],
      },
      "fortnightly": {
        "brackets": [
          { "from": 0, "to": 1400, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 1400, "to": 3400, "type": "fixed", "nearest": 1, "value": 12 },
          { "from": 3400, "to": 6900, "type": "fixed", "nearest": 1, "value": 17 },
          { "from": 6900, "to": 0, "type": "fixed", "nearest": 1, "value": 37 },
        ],
      }

    },


    "fbt": {
      "threshold": 2000,
      "rate": 47,
      "type1": 2.0802,
      "type2": 1.8868,
    },


    "lito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37500, "type": "fixed", "nearest": 1, "value": "700" },
        { "from": 37500, "to": 45000, "type": "rate", "nearest": 1, "start": 700, "end": 325, "value": -5 },
        { "from": 45000, "to": 66667, "type": "rate", "nearest": 1, "start": 325, "end": 0, "value": -1.5 },
        { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "lamito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": 255 },
        { "from": 37000, "to": 48000, "type": "rate", "nearest": 1, "start": 255, "end": 1080, "value": 7.5 },
        { "from": 48000, "to": 90000, "type": "fixed", "nearest": 1, "value": 1080 },
        { "from": 90000, "to": 126000, "type": "rate", "nearest": 1, "start": 1080, "end": 0, "value": -3.0 },
        { "from": 126000, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "mawto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
    },



    "sapto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "single": {
        "brackets": [
          { "from": 0, "to": 32279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 32279, "to": 50120, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 50120, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "married": {
        "brackets": [
          { "from": 0, "to": 28974, "type": "fixed", "nearest": 1, "value": 1602 },
          { "from": 28974, "to": 41790, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 41790, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "illness": {
        "brackets": [
          { "from": 0, "to": 31279, "type": "fixed", "nearest": 1, "value": 2040 },
          { "from": 31279, "to": 47599, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 47599, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      }
    },



    "medicare": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 23226, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 23226, "to": 29033, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 29033, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ],
    },

    "medicareFamily": {
      "incremental": 1,
      "dependants": 3597,
      "brackets": [
        { "from": 0, "to": 39167, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 39167, "to": 48959, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 48959, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    // https://www.ato.gov.au/Individuals/Medicare-levy/Medicare-levy-reduction-for-low-income-earners/
    "medicareSenior": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 36056, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 36056, "to": 45070, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 45070, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSeniorFamily": {
      "incremental": 1,
      "dependants": 3597,
      "brackets": [
        { "from": 0, "to": 51094, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 51094, "to": 63868, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 63868, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },


    "medicareAdjustment": {
      "earningThreshold": { "scale2": 438, "scale6": 739 },
      "shadeInThreshold": { "scale2": 548, "scale6": 924 },
      "annualThreshold": { "scale2": 38474, "scale6": 38474 },
      "additioalChild": { "scale2": 3533, "scale6": 3533 },
      "shadeOutMultiplier": { "scale2": 0.1, "scale6": 0.05 },
      "shadeOutDivisor": { "scale2": 0.08, "scale6": 0.04 },
      "weeklyAdjustment": { "scale2": 438.48, "scale6": 739.88 },
      "medicareLevy": { "scale2": 0.02, "scale6": 0.01 },
    },



    "medicareSurcharge": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 90000, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 105000, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 140000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    "medicareSurchargeFamily": {
      "incremental": 0,
      "dependants": 1500,
      "exemption": 26000,
      "brackets": [
        { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 180000, "to": 210000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 210000, "to": 280000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 280000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },



    "other": [
    ],
    "superannuation": {
      "incremental": 0,
      "maximumContributionBaseQuarter": 57090,
      "concessionalCap": 25000,
      "nonConcesssionalCap": 100000,
      "nonConcessionalExcessTax": 0.47,
      "concessionalTax": 0.15,
      "rate": 9.5,
      "brackets": [
        { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 9.5 }
      ]
    },



    "superannuationCocontribution": {
      "minIncome": 39837,
      "maxIncome": 54837,
      "maxEntitlement": 500,
      "minContribution": 20,
      "reductionFactor": 0.03333,
      "contributionRate": 0.5,
    },

    "superannuationLISTO": {
      "maxIncome": 37000,
      "maxEntitlement": 500,
      "minContribution": 10,
      "contributionRate": 0.15,
    },

    "superannuationCarryForward": {
      "cap": 25000,
    },


    "superannuationSpouseTaxOffset": {
      "spouseIncomeCap": 40000,
      "rate": 0.18,
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": 3000 },
        { "from": 37000, "to": 40000, "type": "rate", "nearest": 1, "start": 3000, "end": 0, "value": -100 },
        { "from": 40000, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "division293": {
      "incremental": 1,
      "threshold": 250000,
      "rate": 15,
      "brackets": [
        { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
        { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
      ]
    },

    "help": {
      "label": "HECS, HELP, VET, SSL, TSL",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 46620, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 46620, "to": 53827, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 53827, "to": 57056, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 57056, "to": 60480, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 60480, "to": 64109, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 64109, "to": 67955, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 67955, "to": 72032, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 72032, "to": 76355, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 76355, "to": 80936, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 80936, "to": 85793, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 85793, "to": 90940, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 90940, "to": 96397, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 96397, "to": 102180, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 102180, "to": 108310, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 108310, "to": 114708, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 114708, "to": 121699, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 121699, "to": 129000, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 129000, "to": 136740, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 136740, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },
    "help_noTaxFree": {
      "label": "HECS, HELP, VET, SSL, TSL - Non resident",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 28420, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 28420, "to": 35627, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 35627, "to": 38856, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 38856, "to": 42280, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 42280, "to": 45909, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 45909, "to": 49755, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 49755, "to": 53822, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 53822, "to": 58155, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 58155, "to": 62736, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 62736, "to": 67593, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 67593, "to": 72740, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 72740, "to": 78197, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 78197, "to": 83980, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 83980, "to": 90110, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 90110, "to": 96508, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 96508, "to": 103499, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 103499, "to": 110800, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 110800, "to": 118540, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 118540, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },

    "sfss": {
      "label": "SFSS",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 46620, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 46620, "to": 53827, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 53827, "to": 57056, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 57056, "to": 60480, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 60480, "to": 64109, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 64109, "to": 67955, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 67955, "to": 72032, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 72032, "to": 76355, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 76355, "to": 80936, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 80936, "to": 85793, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 85793, "to": 90940, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 90940, "to": 96397, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 96397, "to": 102180, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 102180, "to": 108310, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 108310, "to": 114708, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 114708, "to": 121699, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 121699, "to": 129000, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 129000, "to": 136740, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 136740, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },
    "sfss_noTaxFree": {
      "label": "SFSS - Non resident",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 28420, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 28420, "to": 35627, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 35627, "to": 38856, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 38856, "to": 42280, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 42280, "to": 45909, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 45909, "to": 49755, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 49755, "to": 53822, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 53822, "to": 58155, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 58155, "to": 62736, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 62736, "to": 67593, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 67593, "to": 72740, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 72740, "to": 78197, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 78197, "to": 83980, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 83980, "to": 90110, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 90110, "to": 96508, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 96508, "to": 103499, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 103499, "to": 110800, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 110800, "to": 118540, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 118540, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    }
  },



  //                                                                                   
  //                                                                                   
  //              .--~*teu.        .n~~%x.       .--~*teu.      .--~*teu.              
  //             dF     988Nx    x88X   888.    dF     988Nx   dF     988Nx            
  //            d888b   `8888>  X888X   8888L  d888b   `8888> d888b   `8888>           
  //            ?8888>  98888F X8888X   88888  ?8888>  98888F ?8888>  98888F           
  //             "**"  x88888~ 88888X   88888X  "**"  x88888~  "**"  x88888~           
  //                  d8888*`  88888X   88888X       d8888*`        d8888*`            
  //                z8**"`   : 88888X   88888f     z8**"`   :     z8**"`   :           
  //              :?.....  ..F 48888X   88888    :?.....  ..F   :?.....  ..F           
  //             <""888888888~  ?888X   8888"   <""888888888~  <""888888888~           
  //             8:  "888888*    "88X   88*`    8:  "888888*   8:  "888888*            
  //             ""    "**"`       ^"==="`      ""    "**"`    ""    "**"`             
  //                                                                                   
  //         



  {
    "year": "2022", "current": "false",

    "tax": {
      "label": "Australian Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 18200, "to": 45000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 45000, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 359, "a": 0, "b": 0 },
        { "income": 438, "a": 0.19, "b": 68.3462 },
        { "income": 548, "a": 0.29, "b": 112.1942 },
        { "income": 721, "a": 0.21, "b": 68.3465 },
        { "income": 865, "a": 0.219, "b": 74.8369 },
        { "income": 1282, "a": 0.3477, "b": 186.2119 },
        { "income": 2307, "a": 0.345, "b": 182.7504 },
        { "income": 3461, "a": 0.39, "b": 286.5965 },
        { "income": 0, "a": 0.47, "b": 563.5196 }
      ]

    },
    "taxNoFreeThreshold": {
      "label": "No Tax-Free Threshold",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 45000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 45000, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 88, "a": 0.19, "b": 0.19 },
        { "income": 371, "a": 0.2348, "b": 3.9639 },
        { "income": 515, "a": 0.219, "b": -1.9003 },
        { "income": 932, "a": 0.3477, "b": 64.4297 },
        { "income": 1957, "a": 0.345, "b": 61.9132 },
        { "income": 3111, "a": 0.39, "b": 150.0093 },
        { "income": 0, "a": 0.47, "b": 398.9324 }
      ]

    },
    "taxNonResident": {
      "label": "Non Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 2307, "a": 0.325, "b": 0.325 },
        { "income": 3461, "a": 0.37, "b": 103.8462 },
        { "income": 0, "a": 0.45, "b": 380.7692 }
      ]



    },
    "taxBackpacker": {
      "label": "Backpacker",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 45000, "type": "percent", "nearest": 1, "value": 15 },
        { "from": 45000, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
    },

    "taxMedicareHalf": {
      "label": "Half Medicare exemption",
      "incremental": 1,
      "payg": [
        { "income": 359, "a": 0.0, "b": 0.0 },
        { "income": 721, "a": 0.19, "b": 68.3462 },
        { "income": 739, "a": 0.199, "b": 74.8365 },
        { "income": 865, "a": 0.249, "b": 111.8308 },
        { "income": 924, "a": 0.3777, "b": 223.2058 },
        { "income": 1282, "a": 0.3377, "b": 186.2119 },
        { "income": 2307, "a": 0.335, "b": 182.7504 },
        { "income": 3461, "a": 0.38, "b": 286.5965 },
        { "income": 0, "a": 0.4600, "b": 563.5196 },
      ]
    },

    "taxMedicareFull": {
      "label": "Full Medicare exemption",
      "incremental": 1,
      "payg": [
        { "income": 359, "a": 0.0, "b": 0.0 },
        { "income": 721, "a": 0.19, "b": 68.3462 },
        { "income": 865, "a": 0.199, "b": 174.8365 },
        { "income": 1282, "a": 0.3277, "b": 186.2115 },
        { "income": 2307, "a": 0.325, "b": 182.75 },
        { "income": 3461, "a": 0.37, "b": 286.5962 },
        { "income": 0, "a": 0.4500, "b": 563.5192 },
      ]
    },

    "extraWitholding": {
      "label": "Extra witholding",
      "incremental": 1,
      "weekly": {
        "brackets": [
          { "from": 0, "to": 724, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 724, "to": 1700, "type": "fixed", "nearest": 1, "value": 3 },
          { "from": 1700, "to": 3500, "type": "fixed", "nearest": 1, "value": 4 },
          { "from": 3500, "to": 0, "type": "fixed", "nearest": 1, "value": 9 },
        ],
      },
      "fortnightly": {
        "brackets": [
          { "from": 0, "to": 1400, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 1400, "to": 3400, "type": "fixed", "nearest": 1, "value": 12 },
          { "from": 3400, "to": 6900, "type": "fixed", "nearest": 1, "value": 17 },
          { "from": 6900, "to": 0, "type": "fixed", "nearest": 1, "value": 37 },
        ],
      }

    },

    "fbt": {
      "threshold": 2000,
      "rate": 47,
      "type1": 2.0802,
      "type2": 1.8868,
    },


    "lito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37500, "type": "fixed", "nearest": 1, "value": "700" },
        { "from": 37500, "to": 45000, "type": "rate", "nearest": 1, "start": 700, "end": 325, "value": -5 },
        { "from": 45000, "to": 66667, "type": "rate", "nearest": 1, "start": 325, "end": 0, "value": -1.5 },
        { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "lamito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": 255 },
        { "from": 37000, "to": 48000, "type": "rate", "nearest": 1, "start": 255, "end": 1080, "value": 7.5 },
        { "from": 48000, "to": 90000, "type": "fixed", "nearest": 1, "value": 1080 },
        { "from": 90000, "to": 126000, "type": "rate", "nearest": 1, "start": 1080, "end": 0, "value": -3.0 },
        { "from": 126000, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "colto": {
      "label": "Cost of Living Tax Offset (LAMITO)",
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 126000, "type": "fixed", "nearest": 1, "value": 420 },
        { "from": 126000, "to": 0, "type": "fixed", "nearest": 1, "value": 0 },
      ]
    },

    "mawto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
    },


    "sapto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "single": {
        "brackets": [
          { "from": 0, "to": 32279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 32279, "to": 50120, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 50120, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "married": {
        "brackets": [
          { "from": 0, "to": 28974, "type": "fixed", "nearest": 1, "value": 1602 },
          { "from": 28974, "to": 41790, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 41790, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "illness": {
        "brackets": [
          { "from": 0, "to": 31279, "type": "fixed", "nearest": 1, "value": 2040 },
          { "from": 31279, "to": 47599, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 47599, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      }
    },




    // let A = First shade in point
    // Second shade out threshold is  Round( (A*0.1)/(0.1 - 0.02) ) OR round( A * 1.25 )
    // where shade in rate is 10% and standard rate is 2%

    "medicare": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 23365, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 23365, "to": 29206, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 29206, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ],
    },

    "medicareFamily": {
      "incremental": 1,
      "dependants": 3619,
      "brackets": [
        { "from": 0, "to": 39402, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 39402, "to": 49253, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 49253, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    // https://www.ato.gov.au/Individuals/Medicare-levy/Medicare-levy-reduction-for-low-income-earners/
    "medicareSenior": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 36925, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 36925, "to": 46156, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 46156, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSeniorFamily": {
      "incremental": 1,
      "dependants": 3619,
      "brackets": [
        { "from": 0, "to": 51401, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 51401, "to": 64251, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 64251, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },


    "medicareAdjustment": {
      "earningThreshold": { "scale2": 438, "scale6": 739 },
      "shadeInThreshold": { "scale2": 548, "scale6": 924 },
      "annualThreshold": { "scale2": 38474, "scale6": 38474 },
      "additioalChild": { "scale2": 3533, "scale6": 3533 },
      "shadeOutMultiplier": { "scale2": 0.1, "scale6": 0.05 },
      "shadeOutDivisor": { "scale2": 0.08, "scale6": 0.04 },
      "weeklyAdjustment": { "scale2": 438.48, "scale6": 739.88 },
      "medicareLevy": { "scale2": 0.02, "scale6": 0.01 },
    },


    "medicareSurcharge": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 90000, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 105000, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 140000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    "medicareSurchargeFamily": {
      "incremental": 0,
      "dependants": 1500,
      "exemption": 26000,
      "brackets": [
        { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 180000, "to": 210000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 210000, "to": 280000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 280000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    "other": [
    ],
    "superannuation": {
      "incremental": 0,
      "maximumContributionBaseQuarter": 58920,
      "concessionalCap": 27500,
      "nonConcesssionalCap": 110000,
      "nonConcessionalExcessTax": 0.47,
      "concessionalTax": 0.15,
      "rate": 10.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 10 }
      ]
    },



    "superannuationCocontribution": {
      "minIncome": 41112,
      "maxIncome": 56112,
      "maxEntitlement": 500,
      "minContribution": 20,
      "reductionFactor": 0.03333,
      "contributionRate": 0.5,
    },

    "superannuationLISTO": {
      "maxIncome": 37000,
      "maxEntitlement": 500,
      "minContribution": 10,
      "contributionRate": 0.15,
    },

    "superannuationCarryForward": {
      "cap": 25000,
    },


    "superannuationSpouseTaxOffset": {
      "spouseIncomeCap": 40000,
      "rate": 0.18,
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": 3000 },
        { "from": 37000, "to": 40000, "type": "rate", "nearest": 1, "start": 3000, "end": 0, "value": -100 },
        { "from": 40000, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "division293": {
      "incremental": 1,
      "threshold": 250000,
      "rate": 15,
      "brackets": [
        { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
        { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
      ]
    },
    "help": {
      "label": "HECS, HELP, VET, SSL, TSL",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 47014, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 47014, "to": 54283, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 54283, "to": 57539, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 57539, "to": 60992, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 60992, "to": 64652, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 64652, "to": 68530, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 68530, "to": 72642, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 72642, "to": 77002, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 77002, "to": 81621, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 81621, "to": 86519, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 86519, "to": 91710, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 91710, "to": 97212, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 97212, "to": 103046, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 103046, "to": 109228, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 109228, "to": 115782, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 115782, "to": 122729, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 122729, "to": 130093, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 130093, "to": 137898, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 137898, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },
    "help_noTaxFree": {
      "label": "HECS, HELP, VET, SSL, TSL - Non resident",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 28814, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 28814, "to": 36083, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 36083, "to": 39339, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 39339, "to": 42792, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 42792, "to": 46452, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 46452, "to": 50330, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 50330, "to": 54442, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 54442, "to": 58802, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 58802, "to": 63421, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 63421, "to": 68319, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 68319, "to": 73510, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 73510, "to": 79013, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 79013, "to": 84846, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 84846, "to": 91028, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 91028, "to": 97582, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 97582, "to": 104529, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 104529, "to": 111893, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 111893, "to": 119698, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 119698, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },

    "sfss": {
      "label": "SFSS",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 47014, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 47014, "to": 54283, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 54283, "to": 57539, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 57539, "to": 60992, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 60992, "to": 64652, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 64652, "to": 68530, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 68530, "to": 72642, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 72642, "to": 77002, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 77002, "to": 81621, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 81621, "to": 86519, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 86519, "to": 91710, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 91710, "to": 97212, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 97212, "to": 103046, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 103046, "to": 109228, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 109228, "to": 115782, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 115782, "to": 122729, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 122729, "to": 130093, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 130093, "to": 137898, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 137898, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },
    "sfss_noTaxFree": {
      "label": "SFSS - Non resident",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 28814, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 28814, "to": 36083, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 36083, "to": 39339, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 39339, "to": 42792, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 42792, "to": 46452, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 46452, "to": 50330, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 50330, "to": 54442, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 54442, "to": 58802, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 58802, "to": 63421, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 63421, "to": 68319, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 68319, "to": 73510, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 73510, "to": 79013, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 79013, "to": 84846, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 84846, "to": 91028, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 91028, "to": 97582, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 97582, "to": 104529, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 104529, "to": 111893, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 111893, "to": 119698, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 119698, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    }
  },



  //                                                                                   
  //                                                                                   
  //              .--~*teu.        .n~~%x.       .--~*teu.      .x~~"*Weu.             
  //             dF     988Nx    x88X   888.    dF     988Nx   d8Nu.  9888c            
  //            d888b   `8888>  X888X   8888L  d888b   `8888>  88888  98888            
  //            ?8888>  98888F X8888X   88888  ?8888>  98888F  "***"  9888%            
  //             "**"  x88888~ 88888X   88888X  "**"  x88888~       ..@8*"             
  //                  d8888*`  88888X   88888X       d8888*`     ````"8Weu             
  //                z8**"`   : 88888X   88888f     z8**"`   :   ..    ?8888L           
  //              :?.....  ..F 48888X   88888    :?.....  ..F :@88N   '8888N           
  //             <""888888888~  ?888X   8888"   <""888888888~ *8888~  '8888F           
  //             8:  "888888*    "88X   88*`    8:  "888888*  '*8"`   9888%            
  //             ""    "**"`       ^"==="`      ""    "**"`     `~===*%"`              
  //                                                                                   
  //                                                                                   
  //         


  {
    "year": "2023", "current": "false",


    "tax": {
      "label": "Australian Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 18200, "to": 45000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 45000, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 359, "a": 0, "b": 0 },
        { "income": 438, "a": 0.19, "b": 68.3462 },
        { "income": 548, "a": 0.29, "b": 112.1942 },
        { "income": 721, "a": 0.21, "b": 68.3465 },
        { "income": 865, "a": 0.219, "b": 74.8369 },
        { "income": 1282, "a": 0.3477, "b": 186.2119 },
        { "income": 2307, "a": 0.345, "b": 182.7504 },
        { "income": 3461, "a": 0.39, "b": 286.5965 },
        { "income": 0, "a": 0.47, "b": 563.5196 }
      ]

    },

    "taxNoFreeThreshold": {
      "label": "No Tax-Free Threshold",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 45000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 45000, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 88, "a": 0.19, "b": 0.19 },
        { "income": 371, "a": 0.2348, "b": 3.9639 },
        { "income": 515, "a": 0.219, "b": -1.9003 },
        { "income": 932, "a": 0.3477, "b": 64.4297 },
        { "income": 1957, "a": 0.345, "b": 61.9132 },
        { "income": 3111, "a": 0.39, "b": 150.0093 },
        { "income": 0, "a": 0.47, "b": 398.9324 }
      ]

    },
    "taxNonResident": {
      "label": "Non Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 2307, "a": 0.325, "b": 0.325 },
        { "income": 3461, "a": 0.37, "b": 103.8462 },
        { "income": 0, "a": 0.45, "b": 380.7692 }
      ]



    },
    "taxBackpacker": {
      "label": "Backpacker",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 45000, "type": "percent", "nearest": 1, "value": 15 },
        { "from": 45000, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
    },

    "taxMedicareHalf": {
      "label": "Half Medicare exemption",
      "incremental": 1,
      "payg": [
        { "income": 359, "a": 0.0, "b": 0.0 },
        { "income": 721, "a": 0.19, "b": 68.3462 },
        { "income": 739, "a": 0.199, "b": 74.8365 },
        { "income": 865, "a": 0.249, "b": 111.8308 },
        { "income": 924, "a": 0.3777, "b": 223.2058 },
        { "income": 1282, "a": 0.3377, "b": 186.2119 },
        { "income": 2307, "a": 0.335, "b": 182.7504 },
        { "income": 3461, "a": 0.38, "b": 286.5965 },
        { "income": 0, "a": 0.4600, "b": 563.5196 },
      ]
    },

    "taxMedicareFull": {
      "label": "Full Medicare exemption",
      "incremental": 1,
      "payg": [
        { "income": 359, "a": 0.0, "b": 0.0 },
        { "income": 721, "a": 0.19, "b": 68.3462 },
        { "income": 865, "a": 0.199, "b": 74.8365 },
        { "income": 1282, "a": 0.3277, "b": 186.2115 },
        { "income": 2307, "a": 0.325, "b": 182.75 },
        { "income": 3461, "a": 0.37, "b": 286.5962 },
        { "income": 0, "a": 0.4500, "b": 563.5192 },
      ],
    },

    "extraWitholding": {
      "label": "Extra witholding",
      "incremental": 1,
      "weekly": {
        "brackets": [
          { "from": 0, "to": 724, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 724, "to": 1700, "type": "fixed", "nearest": 1, "value": 3 },
          { "from": 1700, "to": 3500, "type": "fixed", "nearest": 1, "value": 4 },
          { "from": 3500, "to": 0, "type": "fixed", "nearest": 1, "value": 9 },
        ],
      },
      "fortnightly": {
        "brackets": [
          { "from": 0, "to": 1400, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 1400, "to": 3400, "type": "fixed", "nearest": 1, "value": 12 },
          { "from": 3400, "to": 6900, "type": "fixed", "nearest": 1, "value": 17 },
          { "from": 6900, "to": 0, "type": "fixed", "nearest": 1, "value": 37 },
        ],
      }

    },


    "fbt": {
      "threshold": 2000,
      "rate": 47,
      "type1": 2.0802,
      "type2": 1.8868,
    },


    "lito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37500, "type": "fixed", "nearest": 1, "value": "700" },
        { "from": 37500, "to": 45000, "type": "rate", "nearest": 1, "start": 700, "end": 325, "value": -5 },
        { "from": 45000, "to": 66667, "type": "rate", "nearest": 1, "start": 325, "end": 0, "value": -1.5 },
        { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "lamito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "mawto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
    },



    "sapto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "single": {
        "brackets": [
          { "from": 0, "to": 32279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 32279, "to": 50120, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 50120, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "married": {
        "brackets": [
          { "from": 0, "to": 28974, "type": "fixed", "nearest": 1, "value": 1602 },
          { "from": 28974, "to": 41790, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 41790, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "illness": {
        "brackets": [
          { "from": 0, "to": 31279, "type": "fixed", "nearest": 1, "value": 2040 },
          { "from": 31279, "to": 47599, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 47599, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      }
    },


    // let A = First shade in point
    // Second shade out threshold is  Round( (A*0.1)/(0.1 - 0.02) ) OR round( A * 1.25 )
    // where shade in rate is 10% and standard rate is 2%

    "medicare": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 24276, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 24276, "to": 30345, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 30345, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ],
    },

    "medicareFamily": {
      "incremental": 1,
      "dependants": 3760,
      "brackets": [
        { "from": 0, "to": 40939, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 40939, "to": 51174, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 51174, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    // https://www.ato.gov.au/Individuals/Medicare-levy/Medicare-levy-reduction-for-low-income-earners/
    "medicareSenior": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 38365, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 38365, "to": 47956, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 47956, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSeniorFamily": {
      "incremental": 1,
      "dependants": 3760,
      "brackets": [
        { "from": 0, "to": 53406, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 53406, "to": 66758, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 66758, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },


    "medicareAdjustment": {
      "earningThreshold": { "scale2": 438, "scale6": 739 },
      "shadeInThreshold": { "scale2": 548, "scale6": 924 },
      "annualThreshold": { "scale2": 38474, "scale6": 38474 },
      "additioalChild": { "scale2": 3533, "scale6": 3533 },
      "shadeOutMultiplier": { "scale2": 0.1, "scale6": 0.05 },
      "shadeOutDivisor": { "scale2": 0.08, "scale6": 0.04 },
      "weeklyAdjustment": { "scale2": 438.48, "scale6": 739.88 },
      "medicareLevy": { "scale2": 0.02, "scale6": 0.01 },
    },


    "medicareSurcharge": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 90000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 90000, "to": 105000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 105000, "to": 140000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 140000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    "medicareSurchargeFamily": {
      "incremental": 0,
      "dependants": 1500,
      "exemption": 26000,
      "brackets": [
        { "from": 0, "to": 180000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 180000, "to": 210000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 210000, "to": 280000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 280000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    "other": [
    ],
    "superannuation": {
      "incremental": 0,
      "maximumContributionBaseQuarter": 60220,
      "concessionalCap": 27500,
      "nonConcesssionalCap": 110000,
      "nonConcessionalExcessTax": 0.47,
      "concessionalTax": 0.15,
      "rate": 10.5,
      "brackets": [
        { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 10.5 }
      ]
    },



    "superannuationCocontribution": {
      "minIncome": 42016,
      "maxIncome": 57016,
      "maxEntitlement": 500,
      "minContribution": 20,
      "reductionFactor": 0.03333,
      "contributionRate": 0.5,
    },

    "superannuationLISTO": {
      "maxIncome": 37000,
      "maxEntitlement": 500,
      "minContribution": 10,
      "contributionRate": 0.15,
    },

    "superannuationCarryForward": {
      "cap": 27500,
    },


    "superannuationSpouseTaxOffset": {
      "spouseIncomeCap": 40000,
      "rate": 0.18,
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": 3000 },
        { "from": 37000, "to": 40000, "type": "rate", "nearest": 1, "start": 3000, "end": 0, "value": -100 },
        { "from": 40000, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "division293": {
      "incremental": 1,
      "threshold": 250000,
      "rate": 15,
      "brackets": [
        { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
        { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
      ]
    },
    "help": {
      "label": "HECS, HELP, VET, SSL, TSL",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 48361, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 48361, "to": 55837, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 55837, "to": 59187, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 59187, "to": 62739, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 62739, "to": 66503, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 66503, "to": 70493, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 70493, "to": 74723, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 74723, "to": 79207, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 79207, "to": 83959, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 83959, "to": 88997, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 88997, "to": 94337, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 94337, "to": 99997, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 99997, "to": 105997, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 105997, "to": 112356, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 112356, "to": 119098, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 119098, "to": 126244, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 126244, "to": 133819, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 133819, "to": 141848, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 141848, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
      "payg": [
        { "income": 930, "a": 0.0, "b": 0 },
        { "income": 1073, "a": 0.01, "b": 0 },
        { "income": 1138, "a": 0.02, "b": 0 },
        { "income": 1206, "a": 0.025, "b": 0 },
        { "income": 1278, "a": 0.03, "b": 0 },
        { "income": 1355, "a": 0.035, "b": 0 },
        { "income": 1436, "a": 0.04, "b": 0 },
        { "income": 1523, "a": 0.045, "b": 0 },
        { "income": 1614, "a": 0.05, "b": 0 },
        { "income": 1711, "a": 0.055, "b": 0 },
        { "income": 1814, "a": 0.06, "b": 0 },
        { "income": 1923, "a": 0.065, "b": 0 },
        { "income": 2038, "a": 0.07, "b": 0 },
        { "income": 2160, "a": 0.075, "b": 0 },
        { "income": 2290, "a": 0.08, "b": 0 },
        { "income": 2427, "a": 0.085, "b": 0 },
        { "income": 2573, "a": 0.09, "b": 0 },
        { "income": 2727, "a": 0.095, "b": 0 },
        { "income": 0, "a": 0.1, "b": 0 },
      ]
    },
    "help_noTaxFree": {
      "label": "HECS, HELP, VET, SSL, TSL - Non resident",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 30161, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 30161, "to": 37637, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 37637, "to": 40987, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 40987, "to": 44539, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 44539, "to": 48303, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 48303, "to": 52293, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 52293, "to": 56523, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 56523, "to": 61007, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 61007, "to": 65759, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 65759, "to": 70797, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 70797, "to": 76137, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 76137, "to": 81797, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 81797, "to": 87797, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 87797, "to": 94156, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 94156, "to": 100898, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 100898, "to": 108044, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 108044, "to": 115619, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 115619, "to": 123648, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 123648, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
      "payg": [
        { "income": 580, "a": 0.0, "b": 0 },
        { "income": 723, "a": 0.01, "b": 0 },
        { "income": 788, "a": 0.02, "b": 0 },
        { "income": 856, "a": 0.025, "b": 0 },
        { "income": 928, "a": 0.03, "b": 0 },
        { "income": 1005, "a": 0.035, "b": 0 },
        { "income": 1086, "a": 0.04, "b": 0 },
        { "income": 1173, "a": 0.045, "b": 0 },
        { "income": 1264, "a": 0.05, "b": 0 },
        { "income": 1361, "a": 0.055, "b": 0 },
        { "income": 1464, "a": 0.06, "b": 0 },
        { "income": 1573, "a": 0.065, "b": 0 },
        { "income": 1688, "a": 0.07, "b": 0 },
        { "income": 1810, "a": 0.075, "b": 0 },
        { "income": 1940, "a": 0.08, "b": 0 },
        { "income": 2077, "a": 0.085, "b": 0 },
        { "income": 2223, "a": 0.09, "b": 0 },
        { "income": 2377, "a": 0.095, "b": 0 },
        { "income": 0, "a": 0.1, "b": 0 },
      ]
    },

    "sfss": {
      "label": "SFSS",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 48361, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 48361, "to": 55837, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 55837, "to": 59187, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 59187, "to": 62739, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 62739, "to": 66503, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 66503, "to": 70493, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 70493, "to": 74723, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 74723, "to": 79207, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 79207, "to": 83959, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 83959, "to": 88997, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 88997, "to": 94337, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 94337, "to": 99997, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 99997, "to": 105997, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 105997, "to": 112356, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 112356, "to": 119098, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 119098, "to": 126244, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 126244, "to": 133819, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 133819, "to": 141848, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 141848, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    },
    "sfss_noTaxFree": {
      "label": "SFSS - Non resident",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 30161, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 30161, "to": 37637, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 37637, "to": 40987, "type": "percent", "nearest": 1, "value": 2.0 },
        { "from": 40987, "to": 44539, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 44539, "to": 48303, "type": "percent", "nearest": 1, "value": 3.0 },
        { "from": 48303, "to": 52293, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 52293, "to": 56523, "type": "percent", "nearest": 1, "value": 4.0 },
        { "from": 56523, "to": 61007, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 61007, "to": 65759, "type": "percent", "nearest": 1, "value": 5.0 },
        { "from": 65759, "to": 70797, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 70797, "to": 76137, "type": "percent", "nearest": 1, "value": 6.0 },
        { "from": 76137, "to": 81797, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 81797, "to": 87797, "type": "percent", "nearest": 1, "value": 7.0 },
        { "from": 87797, "to": 94156, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 94156, "to": 100898, "type": "percent", "nearest": 1, "value": 8.0 },
        { "from": 100898, "to": 108044, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 108044, "to": 115619, "type": "percent", "nearest": 1, "value": 9.0 },
        { "from": 115619, "to": 123648, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 123648, "to": 0, "type": "percent", "nearest": 1, "value": 10.0 }
      ],
    }
  },





  //    2023 - 2024                                                                               
  //                                                                                   
  //             .--~*teu.        .n~~%x.       .--~*teu.            xeee              
  //            dF     988Nx    x88X   888.    dF     988Nx         d888R              
  //           d888b   `8888>  X888X   8888L  d888b   `8888>       d8888R              
  //           ?8888>  98888F X8888X   88888  ?8888>  98888F      @ 8888R              
  //            "**"  x88888~ 88888X   88888X  "**"  x88888~    .P  8888R              
  //                 d8888*`  88888X   88888X       d8888*`    :F   8888R              
  //               z8**"`   : 88888X   88888f     z8**"`   :  x"    8888R              
  //             :?.....  ..F 48888X   88888    :?.....  ..F d8eeeee88888eer           
  //            <""888888888~  ?888X   8888"   <""888888888~        8888R              
  //            8:  "888888*    "88X   88*`    8:  "888888*         8888R              
  //            ""    "**"`       ^"==="`      ""    "**"`       "*%%%%%%**~           
  //                                                                                   
  //      



  {
    "year": "2024", "current": "false",
    "tax": {
      "label": "Australian Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 18200, "to": 45000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 45000, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 359, "a": 0, "b": 0 },
        { "income": 438, "a": 0.19, "b": 68.3462 },
        { "income": 548, "a": 0.29, "b": 112.1942 },
        { "income": 721, "a": 0.21, "b": 68.3465 },
        { "income": 865, "a": 0.219, "b": 74.8369 },
        { "income": 1282, "a": 0.3477, "b": 186.2119 },
        { "income": 2307, "a": 0.345, "b": 182.7504 },
        { "income": 3461, "a": 0.39, "b": 286.5965 },
        { "income": 0, "a": 0.47, "b": 563.5196 }
      ]

    },

    "taxNoFreeThreshold": {
      "label": "No Tax-Free Threshold",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 45000, "type": "percent", "nearest": 1, "value": 19 },
        { "from": 45000, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 88, "a": 0.19, "b": 0.19 },
        { "income": 371, "a": 0.2348, "b": 3.9639 },
        { "income": 515, "a": 0.219, "b": -1.9003 },
        { "income": 932, "a": 0.3477, "b": 64.4297 },
        { "income": 1957, "a": 0.345, "b": 61.9132 },
        { "income": 3111, "a": 0.39, "b": 150.0093 },
        { "income": 0, "a": 0.47, "b": 398.9324 }
      ]

    },
    "taxNonResident": {
      "label": "Non Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 2307, "a": 0.325, "b": 0.325 },
        { "income": 3461, "a": 0.37, "b": 103.8462 },
        { "income": 0, "a": 0.45, "b": 380.7692 }
      ]



    },
    "taxBackpacker": {
      "label": "Backpacker",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 45000, "type": "percent", "nearest": 1, "value": 15 },
        { "from": 45000, "to": 120000, "type": "percent", "nearest": 1, "value": 32.5 },
        { "from": 120000, "to": 180000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 180000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
    },

    "taxMedicareHalf": {
      "label": "Half Medicare exemption",
      "incremental": 1,
      "payg": [
        { "income": 359, "a": 0.0, "b": 0.0 },
        { "income": 721, "a": 0.19, "b": 68.3462 },
        { "income": 739, "a": 0.199, "b": 74.8365 },
        { "income": 865, "a": 0.249, "b": 111.8308 },
        { "income": 924, "a": 0.3777, "b": 223.2058 },
        { "income": 1282, "a": 0.3377, "b": 186.2119 },
        { "income": 2307, "a": 0.335, "b": 182.7504 },
        { "income": 3461, "a": 0.38, "b": 286.5965 },
        { "income": 0, "a": 0.4600, "b": 563.5196 },
      ]
    },

    "taxMedicareFull": {
      "label": "Full Medicare exemption",
      "incremental": 1,
      "payg": [
        { "income": 359, "a": 0.0, "b": 0.0 },
        { "income": 721, "a": 0.19, "b": 68.3462 },
        { "income": 865, "a": 0.199, "b": 74.8365 },
        { "income": 1282, "a": 0.3277, "b": 186.2115 },
        { "income": 2307, "a": 0.325, "b": 182.75 },
        { "income": 3461, "a": 0.37, "b": 286.5962 },
        { "income": 0, "a": 0.4500, "b": 563.5192 },
      ]
    },

    "extraWitholding": {
      "label": "Extra witholding",
      "incremental": 1,
      "weekly": {
        "brackets": [
          { "from": 0, "to": 724, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 724, "to": 1700, "type": "fixed", "nearest": 1, "value": 3 },
          { "from": 1700, "to": 3500, "type": "fixed", "nearest": 1, "value": 4 },
          { "from": 3500, "to": 0, "type": "fixed", "nearest": 1, "value": 9 },
        ],
      },
      "fortnightly": {
        "brackets": [
          { "from": 0, "to": 1400, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 1400, "to": 3400, "type": "fixed", "nearest": 1, "value": 12 },
          { "from": 3400, "to": 6900, "type": "fixed", "nearest": 1, "value": 17 },
          { "from": 6900, "to": 0, "type": "fixed", "nearest": 1, "value": 37 },
        ],
      }

    },

    "fbt": {
      "threshold": 2000,
      "rate": 47,
      "type1": 2.0802,
      "type2": 1.8868,
    },


    "lito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37500, "type": "fixed", "nearest": 1, "value": "700" },
        { "from": 37500, "to": 45000, "type": "rate", "nearest": 1, "start": 700, "end": 325, "value": -5 },
        { "from": 45000, "to": 66667, "type": "rate", "nearest": 1, "start": 325, "end": 0, "value": -1.5 },
        { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "lamito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "mawto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
    },


    "sapto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "single": {
        "brackets": [
          { "from": 0, "to": 32279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 32279, "to": 50120, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 50120, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "married": {
        "brackets": [
          { "from": 0, "to": 28974, "type": "fixed", "nearest": 1, "value": 1602 },
          { "from": 28974, "to": 41790, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 41790, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "illness": {
        "brackets": [
          { "from": 0, "to": 31279, "type": "fixed", "nearest": 1, "value": 2040 },
          { "from": 31279, "to": 47599, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 47599, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      }
    },



    // let A = First shade in point
    // Second shade out threshold is  Round( (A*0.1)/(0.1 - 0.02) ) OR round( A * 1.25 )
    // where shade in rate is 10% and standard rate is 2%

    //Treasury Laws Amendment (Cost of Living—Medicare Levy) Bill 2024
    // 2025 rates brought forward to 2024
    //https://parlinfo.aph.gov.au/parlInfo/download/legislation/bills/r7141_aspassed/toc_pdf/24009b01.pdf;fileType=application%2Fpdf#search=%22legislation/bills/r7141_aspassed/0000%22

    "medicare": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 26000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 26000, "to": 32500, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 32500, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ],
    },

    "medicareFamily": {
      "incremental": 1,
      "dependants": 4027,
      "brackets": [
        { "from": 0, "to": 43846, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 43846, "to": 54807, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 54807, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSenior": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 41089, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 41089, "to": 51361, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 51361, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSeniorFamily": {
      "incremental": 1,
      "dependants": 4027,
      "brackets": [
        { "from": 0, "to": 57198, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 57198, "to": 71497, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 71497, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    /*
    "medicare": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 24276, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 24276, "to": 30345, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 30345, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ],
    },

    "medicareFamily": {
      "incremental": 1,
      "dependants": 3760,
      "brackets": [
        { "from": 0, "to": 40939, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 40939, "to": 51174, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 51174, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    // https://www.ato.gov.au/Individuals/Medicare-levy/Medicare-levy-reduction-for-low-income-earners/
    "medicareSenior": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 38365, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 38365, "to": 47956, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 47956, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSeniorFamily": {
      "incremental": 1,
      "dependants": 3760,
      "brackets": [
        { "from": 0, "to": 53406, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 53406, "to": 66758, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 66758, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },
*/



    "medicareAdjustment": {
      "earningThreshold": { "scale2": 438, "scale6": 739 },
      "shadeInThreshold": { "scale2": 548, "scale6": 924 },
      "annualThreshold": { "scale2": 38474, "scale6": 38474 },
      "additioalChild": { "scale2": 3533, "scale6": 3533 },
      "shadeOutMultiplier": { "scale2": 0.1, "scale6": 0.05 },
      "shadeOutDivisor": { "scale2": 0.08, "scale6": 0.04 },
      "weeklyAdjustment": { "scale2": 438.48, "scale6": 739.88 },
      "medicareLevy": { "scale2": 0.02, "scale6": 0.01 },
    },


    // observer the doller on the threshold
    "medicareSurcharge": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 93000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 93000, "to": 108000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 108000, "to": 144000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 144000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    // A difference method is used and no extra doller is needed - sorry future me
    "medicareSurchargeFamily": {
      "incremental": 0,
      "dependants": 1500,
      "exemption": 26000,
      "brackets": [
        { "from": 0, "to": 186000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 186000, "to": 216000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 216000, "to": 288000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 288000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },



    "other": [
    ],
    "superannuation": {
      "incremental": 0,
      //"maximumContributionBaseQuarter": (27500/0.11)/4,
      "maximumContributionBaseQuarter": 62270,
      "concessionalCap": 27500,
      "nonConcesssionalCap": 110000,
      "nonConcessionalExcessTax": 0.47,
      "concessionalTax": 0.15,
      "rate": 11.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 11.0 }
      ]
    },


    "superannuationCocontribution": {
      "minIncome": 43445,
      "maxIncome": 58445,
      "maxEntitlement": 500,
      "minContribution": 20,
      "reductionFactor": 0.03333,
      "contributionRate": 0.5,
    },

    "superannuationLISTO": {
      "maxIncome": 37000,
      "maxEntitlement": 500,
      "minContribution": 10,
      "contributionRate": 0.15,
    },

    "superannuationCarryForward": {
      "cap": 27500,
    },

    "superannuationSpouseTaxOffset": {
      "spouseIncomeCap": 40000,
      "rate": 0.18,
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": 3000 },
        { "from": 37000, "to": 40000, "type": "rate", "nearest": 1, "start": 3000, "end": 0, "value": -100 },
        { "from": 40000, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },


    "division293": {
      "incremental": 1,
      "threshold": 250000,
      "rate": 15,
      "brackets": [
        { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
        { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
      ]
    },

    // 6.5942% over 2023
    "help": {
      "label": "HECS, HELP, VET, SSL, TSL",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 51550, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 51550, "to": 59519, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 59519, "to": 63090, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 63090, "to": 66876, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 66876, "to": 70889, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 70889, "to": 75141, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 75141, "to": 79650, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 79650, "to": 84430, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 84430, "to": 89495, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 89495, "to": 94866, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 94866, "to": 100558, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 100558, "to": 106591, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 106591, "to": 112986, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 112986, "to": 119765, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 119765, "to": 126951, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 126951, "to": 134569, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 134569, "to": 142643, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 142643, "to": 151202, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 151201, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],

      "payg": [
        { "income": 991, "a": 0.0, "b": 0 },
        { "income": 1144, "a": 0.01, "b": 0 },
        { "income": 1213, "a": 0.02, "b": 0 },
        { "income": 1286, "a": 0.025, "b": 0 },
        { "income": 1363, "a": 0.03, "b": 0 },
        { "income": 1445, "a": 0.035, "b": 0 },
        { "income": 1531, "a": 0.04, "b": 0 },
        { "income": 1623, "a": 0.045, "b": 0 },
        { "income": 1721, "a": 0.05, "b": 0 },
        { "income": 1824, "a": 0.055, "b": 0 },
        { "income": 1933, "a": 0.06, "b": 0 },
        { "income": 2049, "a": 0.065, "b": 0 },
        { "income": 2172, "a": 0.07, "b": 0 },
        { "income": 2303, "a": 0.075, "b": 0 },
        { "income": 2441, "a": 0.08, "b": 0 },
        { "income": 2587, "a": 0.085, "b": 0 },
        { "income": 2743, "a": 0.09, "b": 0 },
        { "income": 2907, "a": 0.095, "b": 0 },
        { "income": 0, "a": 0.1, "b": 0 },
      ]
    },
    "help_noTaxFree": {
      "label": "HECS, HELP, VET, SSL, TSL - Non resident",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 33350, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 33350, "to": 41319, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 41319, "to": 44890, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 44890, "to": 48676, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 48676, "to": 52689, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 52689, "to": 56941, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 56941, "to": 61450, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 61450, "to": 66230, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 66230, "to": 71295, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 71295, "to": 76666, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 76666, "to": 82358, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 82358, "to": 88391, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 88391, "to": 94786, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 94786, "to": 101565, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 101565, "to": 108751, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 108751, "to": 116369, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 116369, "to": 124443, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 124443, "to": 133002, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 133001, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
      "payg": [
        { "income": 641, "a": 0.0, "b": 0 },
        { "income": 794, "a": 0.01, "b": 0 },
        { "income": 863, "a": 0.02, "b": 0 },
        { "income": 936, "a": 0.025, "b": 0 },
        { "income": 1013, "a": 0.03, "b": 0 },
        { "income": 1095, "a": 0.035, "b": 0 },
        { "income": 1181, "a": 0.04, "b": 0 },
        { "income": 1273, "a": 0.045, "b": 0 },
        { "income": 1371, "a": 0.05, "b": 0 },
        { "income": 1474, "a": 0.055, "b": 0 },
        { "income": 1583, "a": 0.06, "b": 0 },
        { "income": 1699, "a": 0.065, "b": 0 },
        { "income": 1822, "a": 0.07, "b": 0 },
        { "income": 1953, "a": 0.075, "b": 0 },
        { "income": 2091, "a": 0.08, "b": 0 },
        { "income": 2237, "a": 0.085, "b": 0 },
        { "income": 2393, "a": 0.09, "b": 0 },
        { "income": 2557, "a": 0.095, "b": 0 },
        { "income": 0, "a": 0.1, "b": 0 },
      ]
    },

    "sfss": {
      "label": "SFSS",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 51550, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 51550, "to": 59519, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 59519, "to": 63090, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 63090, "to": 66876, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 66876, "to": 70889, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 70889, "to": 75141, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 75141, "to": 79650, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 79650, "to": 84430, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 84430, "to": 89495, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 89495, "to": 94866, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 94866, "to": 100558, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 100558, "to": 106591, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 106591, "to": 112986, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 112986, "to": 119765, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 119765, "to": 126951, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 126951, "to": 134569, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 134569, "to": 142643, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 142643, "to": 151202, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 151201, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
      "payg": [
        { "income": 991, "a": 0.0, "b": 0 },
        { "income": 1144, "a": 0.01, "b": 0 },
        { "income": 1213, "a": 0.02, "b": 0 },
        { "income": 1286, "a": 0.025, "b": 0 },
        { "income": 1363, "a": 0.03, "b": 0 },
        { "income": 1445, "a": 0.035, "b": 0 },
        { "income": 1531, "a": 0.04, "b": 0 },
        { "income": 1623, "a": 0.045, "b": 0 },
        { "income": 1721, "a": 0.05, "b": 0 },
        { "income": 1824, "a": 0.055, "b": 0 },
        { "income": 1933, "a": 0.06, "b": 0 },
        { "income": 2049, "a": 0.065, "b": 0 },
        { "income": 2172, "a": 0.07, "b": 0 },
        { "income": 2303, "a": 0.075, "b": 0 },
        { "income": 2441, "a": 0.08, "b": 0 },
        { "income": 2587, "a": 0.085, "b": 0 },
        { "income": 2743, "a": 0.09, "b": 0 },
        { "income": 2907, "a": 0.095, "b": 0 },
        { "income": 0, "a": 0.1, "b": 0 },
      ]
    },
    "sfss_noTaxFree": {
      "label": "SFSS - Non resident",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 33350, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 33350, "to": 41319, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 41319, "to": 44890, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 44890, "to": 48676, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 48676, "to": 52689, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 52689, "to": 56941, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 56941, "to": 61450, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 61450, "to": 66230, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 66230, "to": 71295, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 71295, "to": 76666, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 76666, "to": 82358, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 82358, "to": 88391, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 88391, "to": 94786, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 94786, "to": 101565, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 101565, "to": 108751, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 108751, "to": 116369, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 116369, "to": 124443, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 124443, "to": 133002, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 133001, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
      "payg": [
        { "income": 641, "a": 0.0, "b": 0 },
        { "income": 794, "a": 0.01, "b": 0 },
        { "income": 863, "a": 0.02, "b": 0 },
        { "income": 936, "a": 0.025, "b": 0 },
        { "income": 1013, "a": 0.03, "b": 0 },
        { "income": 1095, "a": 0.035, "b": 0 },
        { "income": 1181, "a": 0.04, "b": 0 },
        { "income": 1273, "a": 0.045, "b": 0 },
        { "income": 1371, "a": 0.05, "b": 0 },
        { "income": 1474, "a": 0.055, "b": 0 },
        { "income": 1583, "a": 0.06, "b": 0 },
        { "income": 1699, "a": 0.065, "b": 0 },
        { "income": 1822, "a": 0.07, "b": 0 },
        { "income": 1953, "a": 0.075, "b": 0 },
        { "income": 2091, "a": 0.08, "b": 0 },
        { "income": 2237, "a": 0.085, "b": 0 },
        { "income": 2393, "a": 0.09, "b": 0 },
        { "income": 2557, "a": 0.095, "b": 0 },
        { "income": 0, "a": 0.1, "b": 0 },
      ]
    }
  },




  //  2024 - 2025                                                                                 
  //                                                                                   
  //             .--~*teu.        .n~~%x.       .--~*teu.      cuuu....uK              
  //            dF     988Nx    x88X   888.    dF     988Nx    888888888               
  //           d888b   `8888>  X888X   8888L  d888b   `8888>   8*888**"                
  //           ?8888>  98888F X8888X   88888  ?8888>  98888F   >  .....                
  //            "**"  x88888~ 88888X   88888X  "**"  x88888~   Lz"  ^888Nu             
  //                 d8888*`  88888X   88888X       d8888*`    F     '8888k            
  //               z8**"`   : 88888X   88888f     z8**"`   :   ..     88888>           
  //             :?.....  ..F 48888X   88888    :?.....  ..F  @888L   88888            
  //            <""888888888~  ?888X   8888"   <""888888888~ '8888F   8888F            
  //            8:  "888888*    "88X   88*`    8:  "888888*   %8F"   d888"             
  //            ""    "**"`       ^"==="`      ""    "**"`     ^"===*%"`               
  //                                                                                   
  //                                                                                   


  {
    "year": "2025", "current": "true",
    "tax": {
      "estimate": "false",
      "label": "Australian Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 18200, "to": 45000, "type": "percent", "nearest": 1, "value": 16 },
        { "from": 45000, "to": 135000, "type": "percent", "nearest": 1, "value": 30 },
        { "from": 135000, "to": 190000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 190000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
      "payg": [
        { "income": 361, "a": 0, "b": 0 },
        { "income": 500, "a": 0.16, "b": 57.8462 },
        { "income": 625, "a": 0.26, "b": 107.8462 },
        { "income": 721, "a": 0.18, "b": 57.8462 },
        { "income": 865, "a": 0.189, "b": 64.3365 },
        { "income": 1282, "a": 0.3227, "b": 180.0385 },
        { "income": 2596, "a": 0.32, "b": 176.5769 },
        { "income": 3653, "a": 0.39, "b": 358.3077 },
        { "income": 0, "a": 0.47, "b": 650.6154 }
      ]

    },
    "taxNoFreeThreshold": {
      "estimate": "false",
      "label": "No Tax-Free Threshold",
      "incremental": 1,
      "brackets": [

        { "from": 0, "to": 45000, "type": "percent", "nearest": 1, "value": 16 },
        { "from": 45000, "to": 135000, "type": "percent", "nearest": 1, "value": 30 },
        { "from": 135000, "to": 190000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 190000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
      "payg": [
        { "income": 150, "a": 0.16, "b": 0.16 },
        { "income": 371, "a": 0.2117, "b": 7.755 },
        { "income": 515, "a": 0.189, "b": -0.6702 },
        { "income": 932, "a": 0.3227, "b": 68.2367 },
        { "income": 2246, "a": 0.32, "b": 65.702 },
        { "income": 3303, "a": 0.39, "b": 222.951 },
        { "income": 0, "a": 0.47, "b": 487.2587 }
      ]

    },
    "taxNonResident": {
      "estimate": "false",
      "label": "Non Resident",
      "incremental": 1,

      "brackets": [
        { "from": 0, "to": 135000, "type": "percent", "nearest": 1, "value": 30 },
        { "from": 135000, "to": 190000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 190000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 2596, "a": 0.3, "b": 0.3 },
        { "income": 3653, "a": 0.37, "b": 181.7308 },
        { "income": 0, "a": 0.45, "b": 474.0385 }
      ]


    },
    "taxBackpacker": {
      "estimate": "false",
      "label": "Backpacker",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 45000, "type": "percent", "nearest": 1, "value": 15 },
        { "from": 45000, "to": 135000, "type": "percent", "nearest": 1, "value": 30.0 },
        { "from": 135000, "to": 190000, "type": "percent", "nearest": 1, "value": 37.0 },
        { "from": 190000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

    },

    "taxMedicareHalf": {
      "estimate": "false",
      "label": "Half Medicare exemption",
      "incremental": 1,
      "payg": [
        { "income": 361, "a": 0.0, "b": 0.0 },
        { "income": 721, "a": 0.16, "b": 57.8462 },
        { "income": 843, "a": 0.169, "b": 64.3365 },
        { "income": 865, "a": 0.219, "b": 106.4962 },
        { "income": 1053, "a": 0.3527, "b": 222.1981 },
        { "income": 1282, "a": 0.3127, "b": 180.0385 },
        { "income": 2596, "a": 0.31, "b": 176.5769 },
        { "income": 3653, "a": 0.38, "b": 358.3077 },
        { "income": 0, "a": 0.4600, "b": 650.6154 },
      ]
    },

    "taxMedicareFull": {
      "estimate": "false",
      "label": "Full Medicare exemption",
      "incremental": 1,
      "payg": [
        { "income": 361, "a": 0.0, "b": 0.0 },
        { "income": 721, "a": 0.16, "b": 57.8462 },
        { "income": 865, "a": 0.169, "b": 64.3365 },
        { "income": 1282, "a": 0.3027, "b": 180.0385 },
        { "income": 2596, "a": 0.3, "b": 176.5769 },
        { "income": 3653, "a": 0.37, "b": 358.3077 },
        { "income": 0, "a": 0.4500, "b": 650.6154 },
      ]
    },

    "extraWitholding": {
      "label": "Extra witholding",
      "incremental": 1,
      "weekly": {
        "brackets": [
          { "from": 0, "to": 875, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 875, "to": 2574, "type": "fixed", "nearest": 1, "value": 3 },
          { "from": 2574, "to": 3649, "type": "fixed", "nearest": 1, "value": 6 },
          { "from": 3649, "to": 0, "type": "fixed", "nearest": 1, "value": 12 },
        ],
      },
      "fortnightly": {
        "brackets": [
          { "from": 0, "to": 1750, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 1750, "to": 5149, "type": "fixed", "nearest": 1, "value": 12 },
          { "from": 5149, "to": 7249, "type": "fixed", "nearest": 1, "value": 26 },
          { "from": 7249, "to": 0, "type": "fixed", "nearest": 1, "value": 47 },
        ],
      }

    },

    "fbt": {
      "threshold": 2000,
      "rate": 47,
      "type1": 2.0802,
      "type2": 1.8868,
    },


    "lito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37500, "type": "fixed", "nearest": 1, "value": "700" },
        { "from": 37500, "to": 45000, "type": "rate", "nearest": 1, "start": 700, "end": 325, "value": -5 },
        { "from": 45000, "to": 66667, "type": "rate", "nearest": 1, "start": 325, "end": 0, "value": -1.5 },
        { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "lamito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "mawto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
    },



    "sapto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "single": {
        "brackets": [
          { "from": 0, "to": 32279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 32279, "to": 50120, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 50120, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "married": {
        "brackets": [
          { "from": 0, "to": 28974, "type": "fixed", "nearest": 1, "value": 1602 },
          { "from": 28974, "to": 41790, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 41790, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "illness": {
        "brackets": [
          { "from": 0, "to": 31279, "type": "fixed", "nearest": 1, "value": 2040 },
          { "from": 31279, "to": 47599, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 47599, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      }
    },




    // let A = First shade in point
    // Second shade out threshold is  Round( (A*0.1)/(0.1 - 0.02) ) OR round( A * 1.25 )
    // where shade in rate is 10% and standard rate is 2%

    // https://www.ato.gov.au/individuals-and-families/medicare-and-private-health-insurance/medicare-levy-surcharge/medicare-levy-surcharge-income-thresholds-and-rates#ato-Incomethresholdandrates202425

    "medicare": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 26000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 26000, "to": 32500, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 32500, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ],
    },

    "medicareFamily": {
      "incremental": 1,
      "dependants": 4027,
      "brackets": [
        { "from": 0, "to": 43846, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 43846, "to": 54807, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 54807, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    // https://www.ato.gov.au/Individuals/Medicare-levy/Medicare-levy-reduction-for-low-income-earners/
    "medicareSenior": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 41089, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 41089, "to": 51361, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 51361, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSeniorFamily": {
      "incremental": 1,
      "dependants": 4027,
      "brackets": [
        { "from": 0, "to": 57198, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 57198, "to": 71497, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 71497, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareAdjustment": {
      "earningThreshold": { "scale2": 500, "scale6": 843 },
      "shadeInThreshold": { "scale2": 625, "scale6": 1053 },
      "annualThreshold": { "scale2": 43846, "scale6": 43846 },
      "additioalChild": { "scale2": 4027, "scale6": 40127 },
      "shadeOutMultiplier": { "scale2": 0.1, "scale6": 0.05 },
      "shadeOutDivisor": { "scale2": 0.08, "scale6": 0.04 },
      "weeklyAdjustment": { "scale2": 5008, "scale6": 843.19 },
      "medicareLevy": { "scale2": 0.02, "scale6": 0.01 },
    },



    "medicareSurcharge": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 97000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 97000, "to": 113000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 113000, "to": 151000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 151000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    "medicareSurchargeFamily": {
      "incremental": 0,
      "dependants": 1500,
      "exemption": 26000,
      "brackets": [
        { "from": 0, "to": 194000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 194000, "to": 226000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 226000, "to": 302000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 302000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },



    "other": [
    ],
    "superannuation": {
      "incremental": 0,
      "maximumContributionBaseQuarter": 65070,
      "concessionalCap": 30000,
      "nonConcesssionalCap": 120000,
      "nonConcessionalExcessTax": 0.47,
      "concessionalTax": 0.15,
      "rate": 11.5,
      "brackets": [
        { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 11.5 }
      ]
    },


    "superannuationCocontribution": {
      "minIncome": 43445,
      "maxIncome": 58445,
      "maxEntitlement": 500,
      "minContribution": 20,
      "reductionFactor": 0.03333,
      "contributionRate": 0.5,
    },

    "superannuationLISTO": {
      "maxIncome": 37000,
      "maxEntitlement": 500,
      "minContribution": 10,
      "contributionRate": 0.15,
    },

    "superannuationCarryForward": {
      "cap": 30000,
    },

    "superannuationSpouseTaxOffset": {
      "spouseIncomeCap": 40000,
      "rate": 0.18,
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": 3000 },
        { "from": 37000, "to": 40000, "type": "rate", "nearest": 1, "start": 3000, "end": 0, "value": -100 },
        { "from": 40000, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },


    "division293": {
      "incremental": 1,
      "threshold": 250000,
      "rate": 15,
      "brackets": [
        { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
        { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
      ]
    },

    // https://www.studyassist.gov.au/paying-back-your-loan/loan-repayment
    // https://www.ato.gov.au/tax-rates-and-codes/schedule-8-calculating-help-ssl-tsl-and-sfss-components
    // Non residents pay the same rate as residents (updated in June 2024)
    "help": {
      "label": "HECS, HELP, VET, SSL, TSL ",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 54435, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 54435, "to": 62850, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 62850, "to": 66620, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 66620, "to": 70618, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 70618, "to": 74855, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 74855, "to": 79346, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 79346, "to": 84107, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 84107, "to": 89154, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 89154, "to": 94503, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 94503, "to": 100174, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 100174, "to": 106185, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 106185, "to": 112556, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 112556, "to": 119309, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 119309, "to": 126467, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 126467, "to": 134056, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 134056, "to": 142100, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 142100, "to": 150626, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 150626, "to": 159663, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 159663, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
    },
    "help_noTaxFree": {
      "label": "HECS, HELP, VET, SSL, TSL",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 36235, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 36235, "to": 44650, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 44650, "to": 48420, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 48420, "to": 52418, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 52418, "to": 56655, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 56655, "to": 61146, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 61146, "to": 65907, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 65907, "to": 70954, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 70954, "to": 76303, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 76303, "to": 81974, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 81974, "to": 87985, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 87985, "to": 94356, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 94356, "to": 101109, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 101109, "to": 108267, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 108267, "to": 115856, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 115856, "to": 123900, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 123900, "to": 132426, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 132426, "to": 141463, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 141463, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
    },

    "sfss": {
      "label": "SFSS",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 54435, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 54435, "to": 62850, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 62850, "to": 66620, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 66620, "to": 70618, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 70618, "to": 74855, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 74855, "to": 79346, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 79346, "to": 84107, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 84107, "to": 89154, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 89154, "to": 94503, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 94503, "to": 100174, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 100174, "to": 106185, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 106185, "to": 112556, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 112556, "to": 119309, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 119309, "to": 126467, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 126467, "to": 134056, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 134056, "to": 142100, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 142100, "to": 150626, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 150626, "to": 159663, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 159663, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
    },
    "sfss_noTaxFree": {
      "label": "SFSS ",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 36235, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 36235, "to": 44650, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 44650, "to": 48420, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 48420, "to": 52418, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 52418, "to": 56655, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 56655, "to": 61146, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 61146, "to": 65907, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 65907, "to": 70954, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 70954, "to": 76303, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 76303, "to": 81974, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 81974, "to": 87985, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 87985, "to": 94356, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 94356, "to": 101109, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 101109, "to": 108267, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 108267, "to": 115856, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 115856, "to": 123900, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 123900, "to": 132426, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 132426, "to": 141463, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 141463, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
    }
  },



  //                                                                                   
  //                                                                                   
  //                                                                                   
  //              .--~*teu.        .n~~%x.       .--~*teu.        .ue~~%u.             
  //             dF     988Nx    x88X   888.    dF     988Nx    .d88   z88i            
  //            d888b   `8888>  X888X   8888L  d888b   `8888>  x888E  *8888            
  //            ?8888>  98888F X8888X   88888  ?8888>  98888F :8888E   ^""             
  //             "**"  x88888~ 88888X   88888X  "**"  x88888~ 98888E.=tWc.             
  //                  d8888*`  88888X   88888X       d8888*`  98888N  '888N            
  //                z8**"`   : 88888X   88888f     z8**"`   : 98888E   8888E           
  //              :?.....  ..F 48888X   88888    :?.....  ..F '8888E   8888E           
  //             <""888888888~  ?888X   8888"   <""888888888~  ?888E   8888"           
  //             8:  "888888*    "88X   88*`    8:  "888888*    "88&   888"            
  //             ""    "**"`       ^"==="`      ""    "**"`       ""==*""              
  //                                                                                   
  //                                                                                   
  //                                                                                   
  //    


  {
    "year": "2026", "current": "false", "estimate": "true",
    "tax": {
      "estimate": "true",
      "label": "Australian Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 18200, "to": 45000, "type": "percent", "nearest": 1, "value": 16 },
        { "from": 45000, "to": 135000, "type": "percent", "nearest": 1, "value": 30 },
        { "from": 135000, "to": 190000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 190000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
      "payg": [
        { "income": 361, "a": 0, "b": 0 },
        { "income": 500, "a": 0.16, "b": 57.8462 },
        { "income": 625, "a": 0.26, "b": 107.8462 },
        { "income": 721, "a": 0.18, "b": 57.8462 },
        { "income": 865, "a": 0.189, "b": 64.3365 },
        { "income": 1282, "a": 0.3227, "b": 180.0385 },
        { "income": 2596, "a": 0.32, "b": 176.5769 },
        { "income": 3653, "a": 0.39, "b": 358.3077 },
        { "income": 0, "a": 0.47, "b": 650.6154 }
      ]

    },
    "taxNoFreeThreshold": {
      "estimate": "true",
      "label": "No Tax-Free Threshold",
      "incremental": 1,
      "brackets": [

        { "from": 0, "to": 45000, "type": "percent", "nearest": 1, "value": 16 },
        { "from": 45000, "to": 135000, "type": "percent", "nearest": 1, "value": 30 },
        { "from": 135000, "to": 190000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 190000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
      "payg": [
        { "income": 150, "a": 0.16, "b": 0.16 },
        { "income": 371, "a": 0.2117, "b": 7.755 },
        { "income": 515, "a": 0.189, "b": -0.6702 },
        { "income": 932, "a": 0.3227, "b": 68.2367 },
        { "income": 2246, "a": 0.32, "b": 65.702 },
        { "income": 3303, "a": 0.39, "b": 222.951 },
        { "income": 0, "a": 0.47, "b": 487.2587 }
      ]

    },
    "taxNonResident": {
      "estimate": "true",
      "label": "Non Resident",
      "incremental": 1,

      "brackets": [
        { "from": 0, "to": 135000, "type": "percent", "nearest": 1, "value": 30 },
        { "from": 135000, "to": 190000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 190000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      "payg": [
        { "income": 2596, "a": 0.3, "b": 0.3 },
        { "income": 3653, "a": 0.37, "b": 181.7308 },
        { "income": 0, "a": 0.45, "b": 474.0385 }
      ]


    },
    "taxBackpacker": {
      "estimate": "true",
      "label": "Backpacker",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 45000, "type": "percent", "nearest": 1, "value": 15 },
        { "from": 45000, "to": 200000, "type": "percent", "nearest": 1, "value": 30.0 },
        { "from": 200000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

    },

    "taxMedicareHalf": {
      "estimate": "true",
      "label": "Half Medicare exemption",
      "incremental": 1,
      "payg": [
        { "income": 361, "a": 0.0, "b": 0.0 },
        { "income": 721, "a": 0.16, "b": 57.8462 },
        { "income": 843, "a": 0.169, "b": 64.3365 },
        { "income": 865, "a": 0.219, "b": 106.4962 },
        { "income": 1053, "a": 0.3527, "b": 222.1981 },
        { "income": 1282, "a": 0.3127, "b": 180.0385 },
        { "income": 2596, "a": 0.31, "b": 176.5769 },
        { "income": 3653, "a": 0.38, "b": 358.3077 },
        { "income": 0, "a": 0.4600, "b": 650.6154 },
      ]
    },

    "taxMedicareFull": {
      "estimate": "true",
      "label": "Full Medicare exemption",
      "incremental": 1,
      "payg": [
        { "income": 361, "a": 0.0, "b": 0.0 },
        { "income": 721, "a": 0.16, "b": 57.8462 },
        { "income": 865, "a": 0.169, "b": 64.3365 },
        { "income": 1282, "a": 0.3027, "b": 180.0385 },
        { "income": 2596, "a": 0.3, "b": 176.5769 },
        { "income": 3653, "a": 0.37, "b": 358.3077 },
        { "income": 0, "a": 0.4500, "b": 650.6154 },
      ]
    },

    "extraWitholding": {
      "label": "Extra witholding",
      "incremental": 1,
      "weekly": {
        "brackets": [
          { "from": 0, "to": 875, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 875, "to": 2574, "type": "fixed", "nearest": 1, "value": 3 },
          { "from": 2574, "to": 3649, "type": "fixed", "nearest": 1, "value": 6 },
          { "from": 3649, "to": 0, "type": "fixed", "nearest": 1, "value": 12 },
        ],
      },
      "fortnightly": {
        "brackets": [
          { "from": 0, "to": 1750, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 1750, "to": 5149, "type": "fixed", "nearest": 1, "value": 12 },
          { "from": 5149, "to": 7249, "type": "fixed", "nearest": 1, "value": 26 },
          { "from": 7249, "to": 0, "type": "fixed", "nearest": 1, "value": 47 },
        ],
      }

    },

    "fbt": {
      "threshold": 2000,
      "rate": 47,
      "type1": 2.0802,
      "type2": 1.8868,
    },


    "lito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37500, "type": "fixed", "nearest": 1, "value": "700" },
        { "from": 37500, "to": 45000, "type": "rate", "nearest": 1, "start": 700, "end": 325, "value": -5 },
        { "from": 45000, "to": 66667, "type": "rate", "nearest": 1, "start": 325, "end": 0, "value": -1.5 },
        { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "lamito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "mawto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
    },



    "sapto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "single": {
        "brackets": [
          { "from": 0, "to": 32279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 32279, "to": 50120, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 50120, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "married": {
        "brackets": [
          { "from": 0, "to": 28974, "type": "fixed", "nearest": 1, "value": 1602 },
          { "from": 28974, "to": 41790, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 41790, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "illness": {
        "brackets": [
          { "from": 0, "to": 31279, "type": "fixed", "nearest": 1, "value": 2040 },
          { "from": 31279, "to": 47599, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 47599, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      }
    },




    // let A = First shade in point
    // Second shade out threshold is  Round( (A*0.1)/(0.1 - 0.02) ) OR round( A * 1.25 )
    // where shade in rate is 10% and standard rate is 2%

    // https://www.ato.gov.au/individuals-and-families/medicare-and-private-health-insurance/medicare-levy-surcharge/medicare-levy-surcharge-income-thresholds-and-rates#ato-Incomethresholdandrates202425

    "medicare": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 27222, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 27222, "to": 34028, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 34028, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ],
    },

    "medicareFamily": {
      "incremental": 1,
      "dependants": 4216,
      "brackets": [
        { "from": 0, "to": 45907, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 45907, "to": 57383, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 57383, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    // https://www.ato.gov.au/Individuals/Medicare-levy/Medicare-levy-reduction-for-low-income-earners/
    "medicareSenior": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 43020, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 43020, "to": 53775, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 53775, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSeniorFamily": {
      "incremental": 1,
      "dependants": 4216,
      "brackets": [
        { "from": 0, "to": 59886, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 59886, "to": 74857, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 74857, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareAdjustment": {
      "earningThreshold": { "scale2": 500, "scale6": 843 },
      "shadeInThreshold": { "scale2": 625, "scale6": 1053 },
      "annualThreshold": { "scale2": 43846, "scale6": 43846 },
      "additioalChild": { "scale2": 4027, "scale6": 40127 },
      "shadeOutMultiplier": { "scale2": 0.1, "scale6": 0.05 },
      "shadeOutDivisor": { "scale2": 0.08, "scale6": 0.04 },
      "weeklyAdjustment": { "scale2": 5008, "scale6": 843.19 },
      "medicareLevy": { "scale2": 0.02, "scale6": 0.01 },
    },



    "medicareSurcharge": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 97000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 97000, "to": 113000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 113000, "to": 151000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 151000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    "medicareSurchargeFamily": {
      "incremental": 0,
      "dependants": 1500,
      "exemption": 26000,
      "brackets": [
        { "from": 0, "to": 194000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 194000, "to": 226000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 226000, "to": 302000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 302000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },



    "other": [
    ],
    "superannuation": {
      "incremental": 0,
      "maximumContributionBaseQuarter": 62500,
      "concessionalCap": 30000,
      "nonConcesssionalCap": 120000,
      "nonConcessionalExcessTax": 0.47,
      "concessionalTax": 0.15,
      "rate": 12.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 12.0 }
      ]
    },


    "superannuationCocontribution": {
      "minIncome": 43445,
      "maxIncome": 58445,
      "maxEntitlement": 500,
      "minContribution": 20,
      "reductionFactor": 0.03333,
      "contributionRate": 0.5,
    },

    "superannuationLISTO": {
      "maxIncome": 37000,
      "maxEntitlement": 500,
      "minContribution": 10,
      "contributionRate": 0.15,
    },

    "superannuationCarryForward": {
      "cap": 30000,
    },

    "superannuationSpouseTaxOffset": {
      "spouseIncomeCap": 40000,
      "rate": 0.18,
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": 3000 },
        { "from": 37000, "to": 40000, "type": "rate", "nearest": 1, "start": 3000, "end": 0, "value": -100 },
        { "from": 40000, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },


    "division293": {
      "incremental": 1,
      "threshold": 250000,
      "rate": 15,
      "brackets": [
        { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
        { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
      ]
    },

    // ESTIMATION BASED ON 3.5% increate over previous year

    "help": {
      "label": "HECS, HELP, VET, SSL, TSL ",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 56340, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 56340, "to": 65050, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 65050, "to": 68952, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 68952, "to": 73090, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 73090, "to": 77475, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 77475, "to": 82123, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 82123, "to": 87051, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 87051, "to": 92274, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 92274, "to": 97811, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 97811, "to": 103680, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 103680, "to": 109901, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 109901, "to": 116495, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 116495, "to": 123485, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 123485, "to": 130893, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 130893, "to": 138748, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 138748, "to": 147074, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 147074, "to": 155898, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 155898, "to": 165251, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 165251, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
    },
    "help_noTaxFree": {
      "label": "HECS, HELP, VET, SSL, TSL",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 37503, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 37503, "to": 46213, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 46213, "to": 50115, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 50115, "to": 54253, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 54253, "to": 58638, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 58638, "to": 63286, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 63286, "to": 68214, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 68214, "to": 73437, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 73437, "to": 78974, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 78974, "to": 84843, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 84843, "to": 91064, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 91064, "to": 97658, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 97658, "to": 104648, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 104648, "to": 112056, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 112056, "to": 119911, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 119911, "to": 128236, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 128236, "to": 137061, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 137061, "to": 146414, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 146414, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
    },

    "sfss": {
      "label": "SFSS",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 56340, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 56340, "to": 65050, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 65050, "to": 68952, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 68952, "to": 73090, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 73090, "to": 77475, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 77475, "to": 82123, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 82123, "to": 87051, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 87051, "to": 92274, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 92274, "to": 97811, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 97811, "to": 103680, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 103680, "to": 109901, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 109901, "to": 116495, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 116495, "to": 123485, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 123485, "to": 130893, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 130893, "to": 138748, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 138748, "to": 147074, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 147074, "to": 155898, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 155898, "to": 165251, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 165251, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
    },
    "sfss_noTaxFree": {
      "label": "SFSS ",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 37503, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 37503, "to": 46213, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 46213, "to": 50115, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 50115, "to": 54253, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 54253, "to": 58638, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 58638, "to": 63286, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 63286, "to": 68214, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 68214, "to": 73437, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 73437, "to": 78974, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 78974, "to": 84843, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 84843, "to": 91064, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 91064, "to": 97658, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 97658, "to": 104648, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 104648, "to": 112056, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 112056, "to": 119911, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 119911, "to": 128236, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 128236, "to": 137061, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 137061, "to": 146414, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 146414, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
    }
  },





  //       .--~*teu.        .n~~%x.       .--~*teu.    dL ud8Nu  :8c 
  //       dF     988Nx    x88X   888.    dF     988Nx  8Fd888888L %8 
  //       d888b   `8888>  X888X   8888L  d888b   `8888> 4N88888888cuR 
  //       ?8888>  98888F X8888X   88888  ?8888>  98888F 4F   ^""%""d  
  //       "**"  x88888~ 88888X   88888X  "**"  x88888~ d       .z8   
  //            d8888*`  88888X   88888X       d8888*`  ^     z888    
  //          z8**"`   : 88888X   88888f     z8**"`   :     d8888'    
  //        :?.....  ..F 48888X   88888    :?.....  ..F    888888     
  //       <""888888888~  ?888X   8888"   <""888888888~   :888888     
  //       8:  "888888*    "88X   88*`    8:  "888888*     888888     
  //       ""    "**"`       ^"==="`      ""    "**"`      '%**%   


  {
    "year": "2027", "current": "false", "estimate": "true",
    "tax": {
      "estimate": "true",
      "label": "Australian Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 18200, "to": 45000, "type": "percent", "nearest": 1, "value": 15 },
        { "from": 45000, "to": 135000, "type": "percent", "nearest": 1, "value": 30 },
        { "from": 135000, "to": 190000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 190000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
      // "payg": [
      //   { "income": 361, "a": 0, "b": 0 },
      //   { "income": 500, "a": 0.16, "b": 57.8462 },
      //   { "income": 625, "a": 0.26, "b": 107.8462 },
      //   { "income": 721, "a": 0.18, "b": 57.8462 },
      //   { "income": 865, "a": 0.189, "b": 64.3365 },
      //   { "income": 1282, "a": 0.3227, "b": 180.0385 },
      //   { "income": 2596, "a": 0.32, "b": 176.5769 },
      //   { "income": 3653, "a": 0.39, "b": 358.3077 },
      //   { "income": 0, "a": 0.47, "b": 650.6154 }
      // ]

    },
    "taxNoFreeThreshold": {
      "estimate": "true",
      "label": "No Tax-Free Threshold",
      "incremental": 1,
      "brackets": [

        { "from": 0, "to": 45000, "type": "percent", "nearest": 1, "value": 16 },
        { "from": 45000, "to": 135000, "type": "percent", "nearest": 1, "value": 30 },
        { "from": 135000, "to": 190000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 190000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
      // "payg": [
      //   { "income": 150, "a": 0.16, "b": 0.16 },
      //   { "income": 371, "a": 0.2117, "b": 7.755 },
      //   { "income": 515, "a": 0.189, "b": -0.6702 },
      //   { "income": 932, "a": 0.3227, "b": 68.2367 },
      //   { "income": 2246, "a": 0.32, "b": 65.702 },
      //   { "income": 3303, "a": 0.39, "b": 222.951 },
      //   { "income": 0, "a": 0.47, "b": 487.2587 }
      // ]

    },
    "taxNonResident": {
      "estimate": "true",
      "label": "Non Resident",
      "incremental": 1,

      "brackets": [
        { "from": 0, "to": 135000, "type": "percent", "nearest": 1, "value": 30 },
        { "from": 135000, "to": 190000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 190000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      // "payg": [
      //   { "income": 2596, "a": 0.3, "b": 0.3 },
      //   { "income": 3653, "a": 0.37, "b": 181.7308 },
      //   { "income": 0, "a": 0.45, "b": 474.0385 }
      // ]


    },
    "taxBackpacker": {
      "estimate": "true",
      "label": "Backpacker",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 45000, "type": "percent", "nearest": 1, "value": 15 },
        { "from": 45000, "to": 200000, "type": "percent", "nearest": 1, "value": 30.0 },
        { "from": 200000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

    },

    // "taxMedicareHalf": {
    //   "estimate": "true",
    //   "label": "Half Medicare exemption",
    //   "incremental": 1,
    //   "payg": [
    //     { "income": 361, "a": 0.0, "b": 0.0 },
    //     { "income": 721, "a": 0.16, "b": 57.8462 },
    //     { "income": 843, "a": 0.169, "b": 64.3365 },
    //     { "income": 865, "a": 0.219, "b": 106.4962 },
    //     { "income": 1053, "a": 0.3527, "b": 222.1981 },
    //     { "income": 1282, "a": 0.3127, "b": 180.0385 },
    //     { "income": 2596, "a": 0.31, "b": 176.5769 },
    //     { "income": 3653, "a": 0.38, "b": 358.3077 },
    //     { "income": 0, "a": 0.4600, "b": 650.6154 },
    //   ]
    // },

    // "taxMedicareFull": {
    //   "estimate": "true",
    //   "label": "Full Medicare exemption",
    //   "incremental": 1,
    //   "payg": [
    //     { "income": 361, "a": 0.0, "b": 0.0 },
    //     { "income": 721, "a": 0.16, "b": 57.8462 },
    //     { "income": 865, "a": 0.169, "b": 64.3365 },
    //     { "income": 1282, "a": 0.3027, "b": 180.0385 },
    //     { "income": 2596, "a": 0.3, "b": 176.5769 },
    //     { "income": 3653, "a": 0.37, "b": 358.3077 },
    //     { "income": 0, "a": 0.4500, "b": 650.6154 },
    //   ]
    // },

    "extraWitholding": {
      "label": "Extra witholding",
      "incremental": 1,
      "weekly": {
        "brackets": [
          { "from": 0, "to": 875, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 875, "to": 2574, "type": "fixed", "nearest": 1, "value": 3 },
          { "from": 2574, "to": 3649, "type": "fixed", "nearest": 1, "value": 6 },
          { "from": 3649, "to": 0, "type": "fixed", "nearest": 1, "value": 12 },
        ],
      },
      "fortnightly": {
        "brackets": [
          { "from": 0, "to": 1750, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 1750, "to": 5149, "type": "fixed", "nearest": 1, "value": 12 },
          { "from": 5149, "to": 7249, "type": "fixed", "nearest": 1, "value": 26 },
          { "from": 7249, "to": 0, "type": "fixed", "nearest": 1, "value": 47 },
        ],
      }

    },

    "fbt": {
      "threshold": 2000,
      "rate": 47,
      "type1": 2.0802,
      "type2": 1.8868,
    },


    "lito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37500, "type": "fixed", "nearest": 1, "value": "700" },
        { "from": 37500, "to": 45000, "type": "rate", "nearest": 1, "start": 700, "end": 325, "value": -5 },
        { "from": 45000, "to": 66667, "type": "rate", "nearest": 1, "start": 325, "end": 0, "value": -1.5 },
        { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "lamito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "mawto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
    },



    "sapto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "single": {
        "brackets": [
          { "from": 0, "to": 32279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 32279, "to": 50120, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 50120, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "married": {
        "brackets": [
          { "from": 0, "to": 28974, "type": "fixed", "nearest": 1, "value": 1602 },
          { "from": 28974, "to": 41790, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 41790, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "illness": {
        "brackets": [
          { "from": 0, "to": 31279, "type": "fixed", "nearest": 1, "value": 2040 },
          { "from": 31279, "to": 47599, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 47599, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      }
    },




    // let A = First shade in point
    // Second shade out threshold is  Round( (A*0.1)/(0.1 - 0.02) ) OR round( A * 1.25 )
    // where shade in rate is 10% and standard rate is 2%

    // https://www.ato.gov.au/individuals-and-families/medicare-and-private-health-insurance/medicare-levy-surcharge/medicare-levy-surcharge-income-thresholds-and-rates#ato-Incomethresholdandrates202425

    "medicare": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 27222, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 27222, "to": 34028, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 34028, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ],
    },

    "medicareFamily": {
      "incremental": 1,
      "dependants": 4216,
      "brackets": [
        { "from": 0, "to": 45907, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 45907, "to": 57383, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 57383, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    // https://www.ato.gov.au/Individuals/Medicare-levy/Medicare-levy-reduction-for-low-income-earners/
    "medicareSenior": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 43020, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 43020, "to": 53775, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 53775, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSeniorFamily": {
      "incremental": 1,
      "dependants": 4216,
      "brackets": [
        { "from": 0, "to": 59886, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 59886, "to": 74857, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 74857, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareAdjustment": {
      "earningThreshold": { "scale2": 500, "scale6": 843 },
      "shadeInThreshold": { "scale2": 625, "scale6": 1053 },
      "annualThreshold": { "scale2": 43846, "scale6": 43846 },
      "additioalChild": { "scale2": 4027, "scale6": 40127 },
      "shadeOutMultiplier": { "scale2": 0.1, "scale6": 0.05 },
      "shadeOutDivisor": { "scale2": 0.08, "scale6": 0.04 },
      "weeklyAdjustment": { "scale2": 5008, "scale6": 843.19 },
      "medicareLevy": { "scale2": 0.02, "scale6": 0.01 },
    },



    "medicareSurcharge": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 97000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 97000, "to": 113000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 113000, "to": 151000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 151000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    "medicareSurchargeFamily": {
      "incremental": 0,
      "dependants": 1500,
      "exemption": 26000,
      "brackets": [
        { "from": 0, "to": 194000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 194000, "to": 226000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 226000, "to": 302000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 302000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },



    "other": [
    ],
    "superannuation": {
      "incremental": 0,
      "maximumContributionBaseQuarter": 62500,
      "concessionalCap": 30000,
      "nonConcesssionalCap": 120000,
      "nonConcessionalExcessTax": 0.47,
      "concessionalTax": 0.15,
      "rate": 12.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 12.0 }
      ]
    },


    "superannuationCocontribution": {
      "minIncome": 43445,
      "maxIncome": 58445,
      "maxEntitlement": 500,
      "minContribution": 20,
      "reductionFactor": 0.03333,
      "contributionRate": 0.5,
    },

    "superannuationLISTO": {
      "maxIncome": 37000,
      "maxEntitlement": 500,
      "minContribution": 10,
      "contributionRate": 0.15,
    },

    "superannuationCarryForward": {
      "cap": 30000,
    },

    "superannuationSpouseTaxOffset": {
      "spouseIncomeCap": 40000,
      "rate": 0.18,
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": 3000 },
        { "from": 37000, "to": 40000, "type": "rate", "nearest": 1, "start": 3000, "end": 0, "value": -100 },
        { "from": 40000, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },


    "division293": {
      "incremental": 1,
      "threshold": 250000,
      "rate": 15,
      "brackets": [
        { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
        { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
      ]
    },

    // ESTIMATION BASED ON 3.5% increate over previous year

    "help": {
      "label": "HECS, HELP, VET, SSL, TSL ",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 56340, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 56340, "to": 65050, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 65050, "to": 68952, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 68952, "to": 73090, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 73090, "to": 77475, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 77475, "to": 82123, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 82123, "to": 87051, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 87051, "to": 92274, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 92274, "to": 97811, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 97811, "to": 103680, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 103680, "to": 109901, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 109901, "to": 116495, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 116495, "to": 123485, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 123485, "to": 130893, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 130893, "to": 138748, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 138748, "to": 147074, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 147074, "to": 155898, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 155898, "to": 165251, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 165251, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
    },
    "help_noTaxFree": {
      "label": "HECS, HELP, VET, SSL, TSL",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 37503, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 37503, "to": 46213, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 46213, "to": 50115, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 50115, "to": 54253, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 54253, "to": 58638, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 58638, "to": 63286, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 63286, "to": 68214, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 68214, "to": 73437, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 73437, "to": 78974, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 78974, "to": 84843, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 84843, "to": 91064, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 91064, "to": 97658, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 97658, "to": 104648, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 104648, "to": 112056, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 112056, "to": 119911, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 119911, "to": 128236, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 128236, "to": 137061, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 137061, "to": 146414, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 146414, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
    },

    "sfss": {
      "label": "SFSS",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 56340, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 56340, "to": 65050, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 65050, "to": 68952, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 68952, "to": 73090, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 73090, "to": 77475, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 77475, "to": 82123, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 82123, "to": 87051, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 87051, "to": 92274, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 92274, "to": 97811, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 97811, "to": 103680, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 103680, "to": 109901, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 109901, "to": 116495, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 116495, "to": 123485, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 123485, "to": 130893, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 130893, "to": 138748, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 138748, "to": 147074, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 147074, "to": 155898, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 155898, "to": 165251, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 165251, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
    },
    "sfss_noTaxFree": {
      "label": "SFSS ",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 37503, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 37503, "to": 46213, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 46213, "to": 50115, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 50115, "to": 54253, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 54253, "to": 58638, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 58638, "to": 63286, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 63286, "to": 68214, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 68214, "to": 73437, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 73437, "to": 78974, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 78974, "to": 84843, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 84843, "to": 91064, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 91064, "to": 97658, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 97658, "to": 104648, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 104648, "to": 112056, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 112056, "to": 119911, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 119911, "to": 128236, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 128236, "to": 137061, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 137061, "to": 146414, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 146414, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
    }
  },






//          .--~*teu.        .n~~%x.       .--~*teu.       u+=~~~+u.    
//         dF     988Nx    x88X   888.    dF     988Nx   z8F      `8N.  
//        d888b   `8888>  X888X   8888L  d888b   `8888> d88L       98E  
//        ?8888>  98888F X8888X   88888  ?8888>  98888F 98888bu.. .@*   
//        "**"  x88888~ 88888X   88888X  "**"  x88888~ "88888888NNu.   
//             d8888*`  88888X   88888X       d8888*`   "*8888888888i  
//           z8**"`   : 88888X   88888f     z8**"`   :  .zf""*8888888L 
//         :?.....  ..F 48888X   88888    :?.....  ..F d8F      ^%888E 
//        <""888888888~  ?888X   8888"   <""888888888~ 88>        `88~ 
//        8:  "888888*    "88X   88*`    8:  "888888*  '%N.       d*"  
//        ""    "**"`       ^"==="`      ""    "**"`      ^"====="`    
                                                             
                                                             


{
    "year": "2028", "current": "false", "estimate": "true",
    "tax": {
      "estimate": "true",
      "label": "Australian Resident",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 18200, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 18200, "to": 45000, "type": "percent", "nearest": 1, "value": 14 },
        { "from": 45000, "to": 135000, "type": "percent", "nearest": 1, "value": 30 },
        { "from": 135000, "to": 190000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 190000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
      // "payg": [
      //   { "income": 361, "a": 0, "b": 0 },
      //   { "income": 500, "a": 0.16, "b": 57.8462 },
      //   { "income": 625, "a": 0.26, "b": 107.8462 },
      //   { "income": 721, "a": 0.18, "b": 57.8462 },
      //   { "income": 865, "a": 0.189, "b": 64.3365 },
      //   { "income": 1282, "a": 0.3227, "b": 180.0385 },
      //   { "income": 2596, "a": 0.32, "b": 176.5769 },
      //   { "income": 3653, "a": 0.39, "b": 358.3077 },
      //   { "income": 0, "a": 0.47, "b": 650.6154 }
      // ]

    },
    "taxNoFreeThreshold": {
      "estimate": "true",
      "label": "No Tax-Free Threshold",
      "incremental": 1,
      "brackets": [

        { "from": 0, "to": 45000, "type": "percent", "nearest": 1, "value": 16 },
        { "from": 45000, "to": 135000, "type": "percent", "nearest": 1, "value": 30 },
        { "from": 135000, "to": 190000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 190000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],
      // "payg": [
      //   { "income": 150, "a": 0.16, "b": 0.16 },
      //   { "income": 371, "a": 0.2117, "b": 7.755 },
      //   { "income": 515, "a": 0.189, "b": -0.6702 },
      //   { "income": 932, "a": 0.3227, "b": 68.2367 },
      //   { "income": 2246, "a": 0.32, "b": 65.702 },
      //   { "income": 3303, "a": 0.39, "b": 222.951 },
      //   { "income": 0, "a": 0.47, "b": 487.2587 }
      // ]

    },
    "taxNonResident": {
      "estimate": "true",
      "label": "Non Resident",
      "incremental": 1,

      "brackets": [
        { "from": 0, "to": 135000, "type": "percent", "nearest": 1, "value": 30 },
        { "from": 135000, "to": 190000, "type": "percent", "nearest": 1, "value": 37 },
        { "from": 190000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

      // "payg": [
      //   { "income": 2596, "a": 0.3, "b": 0.3 },
      //   { "income": 3653, "a": 0.37, "b": 181.7308 },
      //   { "income": 0, "a": 0.45, "b": 474.0385 }
      // ]


    },
    "taxBackpacker": {
      "estimate": "true",
      "label": "Backpacker",
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 45000, "type": "percent", "nearest": 1, "value": 15 },
        { "from": 45000, "to": 200000, "type": "percent", "nearest": 1, "value": 30.0 },
        { "from": 200000, "to": 0, "type": "percent", "nearest": 1, "value": 45 }],

    },

    // "taxMedicareHalf": {
    //   "estimate": "true",
    //   "label": "Half Medicare exemption",
    //   "incremental": 1,
    //   "payg": [
    //     { "income": 361, "a": 0.0, "b": 0.0 },
    //     { "income": 721, "a": 0.16, "b": 57.8462 },
    //     { "income": 843, "a": 0.169, "b": 64.3365 },
    //     { "income": 865, "a": 0.219, "b": 106.4962 },
    //     { "income": 1053, "a": 0.3527, "b": 222.1981 },
    //     { "income": 1282, "a": 0.3127, "b": 180.0385 },
    //     { "income": 2596, "a": 0.31, "b": 176.5769 },
    //     { "income": 3653, "a": 0.38, "b": 358.3077 },
    //     { "income": 0, "a": 0.4600, "b": 650.6154 },
    //   ]
    // },

    // "taxMedicareFull": {
    //   "estimate": "true",
    //   "label": "Full Medicare exemption",
    //   "incremental": 1,
    //   "payg": [
    //     { "income": 361, "a": 0.0, "b": 0.0 },
    //     { "income": 721, "a": 0.16, "b": 57.8462 },
    //     { "income": 865, "a": 0.169, "b": 64.3365 },
    //     { "income": 1282, "a": 0.3027, "b": 180.0385 },
    //     { "income": 2596, "a": 0.3, "b": 176.5769 },
    //     { "income": 3653, "a": 0.37, "b": 358.3077 },
    //     { "income": 0, "a": 0.4500, "b": 650.6154 },
    //   ]
    // },

    "extraWitholding": {
      "label": "Extra witholding",
      "incremental": 1,
      "weekly": {
        "brackets": [
          { "from": 0, "to": 875, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 875, "to": 2574, "type": "fixed", "nearest": 1, "value": 3 },
          { "from": 2574, "to": 3649, "type": "fixed", "nearest": 1, "value": 6 },
          { "from": 3649, "to": 0, "type": "fixed", "nearest": 1, "value": 12 },
        ],
      },
      "fortnightly": {
        "brackets": [
          { "from": 0, "to": 1750, "type": "fixed", "nearest": 1, "value": 0 },
          { "from": 1750, "to": 5149, "type": "fixed", "nearest": 1, "value": 12 },
          { "from": 5149, "to": 7249, "type": "fixed", "nearest": 1, "value": 26 },
          { "from": 7249, "to": 0, "type": "fixed", "nearest": 1, "value": 47 },
        ],
      }

    },

    "fbt": {
      "threshold": 2000,
      "rate": 47,
      "type1": 2.0802,
      "type2": 1.8868,
    },


    "lito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 37500, "type": "fixed", "nearest": 1, "value": "700" },
        { "from": 37500, "to": 45000, "type": "rate", "nearest": 1, "start": 700, "end": 325, "value": -5 },
        { "from": 45000, "to": 66667, "type": "rate", "nearest": 1, "start": 325, "end": 0, "value": -1.5 },
        { "from": 66667, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "lamito": {
      "incremental": 0,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },

    "mawto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }]
    },



    "sapto": {
      "incremental": 1,
      "paygLoading": 1.0,
      "single": {
        "brackets": [
          { "from": 0, "to": 32279, "type": "fixed", "nearest": 1, "value": 2230 },
          { "from": 32279, "to": 50120, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 50120, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "married": {
        "brackets": [
          { "from": 0, "to": 28974, "type": "fixed", "nearest": 1, "value": 1602 },
          { "from": 28974, "to": 41790, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 41790, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      },
      "illness": {
        "brackets": [
          { "from": 0, "to": 31279, "type": "fixed", "nearest": 1, "value": 2040 },
          { "from": 31279, "to": 47599, "type": "percent", "nearest": 1, "value": -12.5 },
          { "from": 47599, "to": 0, "type": "fixed", "nearest": 1, "value": 0, "incremental": "false" }
        ]
      }
    },




    // let A = First shade in point
    // Second shade out threshold is  Round( (A*0.1)/(0.1 - 0.02) ) OR round( A * 1.25 )
    // where shade in rate is 10% and standard rate is 2%

    // https://www.ato.gov.au/individuals-and-families/medicare-and-private-health-insurance/medicare-levy-surcharge/medicare-levy-surcharge-income-thresholds-and-rates#ato-Incomethresholdandrates202425

    "medicare": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 27222, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 27222, "to": 34028, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 34028, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ],
    },

    "medicareFamily": {
      "incremental": 1,
      "dependants": 4216,
      "brackets": [
        { "from": 0, "to": 45907, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 45907, "to": 57383, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 57383, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    // https://www.ato.gov.au/Individuals/Medicare-levy/Medicare-levy-reduction-for-low-income-earners/
    "medicareSenior": {
      "incremental": 1,
      "brackets": [
        { "from": 0, "to": 43020, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 43020, "to": 53775, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 53775, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareSeniorFamily": {
      "incremental": 1,
      "dependants": 4216,
      "brackets": [
        { "from": 0, "to": 59886, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 59886, "to": 74857, "type": "percent", "nearest": 1, "value": 10.0 },
        { "from": 74857, "to": 0, "type": "percent", "nearest": 1, "value": 2.0, "incremental": "false" }
      ]
    },

    "medicareAdjustment": {
      "earningThreshold": { "scale2": 500, "scale6": 843 },
      "shadeInThreshold": { "scale2": 625, "scale6": 1053 },
      "annualThreshold": { "scale2": 43846, "scale6": 43846 },
      "additioalChild": { "scale2": 4027, "scale6": 40127 },
      "shadeOutMultiplier": { "scale2": 0.1, "scale6": 0.05 },
      "shadeOutDivisor": { "scale2": 0.08, "scale6": 0.04 },
      "weeklyAdjustment": { "scale2": 5008, "scale6": 843.19 },
      "medicareLevy": { "scale2": 0.02, "scale6": 0.01 },
    },



    "medicareSurcharge": {
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 97000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 97000, "to": 113000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 113000, "to": 151000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 151000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },

    "medicareSurchargeFamily": {
      "incremental": 0,
      "dependants": 1500,
      "exemption": 26000,
      "brackets": [
        { "from": 0, "to": 194000, "type": "percent", "nearest": 1, "value": 0 },
        { "from": 194000, "to": 226000, "type": "percent", "nearest": 1, "value": 1.0 },
        { "from": 226000, "to": 302000, "type": "percent", "nearest": 1, "value": 1.25 },
        { "from": 302000, "to": 0, "type": "percent", "nearest": 1, "value": 1.5 }
      ]
    },



    "other": [
    ],
    "superannuation": {
      "incremental": 0,
      "maximumContributionBaseQuarter": 62500,
      "concessionalCap": 30000,
      "nonConcesssionalCap": 120000,
      "nonConcessionalExcessTax": 0.47,
      "concessionalTax": 0.15,
      "rate": 12.0,
      "brackets": [
        { "from": 0, "to": 0, "type": "percent", "nearest": 1, "value": 12.0 }
      ]
    },


    "superannuationCocontribution": {
      "minIncome": 43445,
      "maxIncome": 58445,
      "maxEntitlement": 500,
      "minContribution": 20,
      "reductionFactor": 0.03333,
      "contributionRate": 0.5,
    },

    "superannuationLISTO": {
      "maxIncome": 37000,
      "maxEntitlement": 500,
      "minContribution": 10,
      "contributionRate": 0.15,
    },

    "superannuationCarryForward": {
      "cap": 30000,
    },

    "superannuationSpouseTaxOffset": {
      "spouseIncomeCap": 40000,
      "rate": 0.18,
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 37000, "type": "fixed", "nearest": 1, "value": 3000 },
        { "from": 37000, "to": 40000, "type": "rate", "nearest": 1, "start": 3000, "end": 0, "value": -100 },
        { "from": 40000, "to": 0, "type": "fixed", "nearest": 1, "value": 0 }
      ]
    },


    "division293": {
      "incremental": 1,
      "threshold": 250000,
      "rate": 15,
      "brackets": [
        { "from": 0, "to": 250000, "type": "percent", "nearest": 1, "value": 0.0 },
        { "from": 250000, "to": 0, "type": "percent", "nearest": 1, "value": 15.0 }
      ]
    },

    // ESTIMATION BASED ON 3.5% increate over previous year

    "help": {
      "label": "HECS, HELP, VET, SSL, TSL ",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 56340, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 56340, "to": 65050, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 65050, "to": 68952, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 68952, "to": 73090, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 73090, "to": 77475, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 77475, "to": 82123, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 82123, "to": 87051, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 87051, "to": 92274, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 92274, "to": 97811, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 97811, "to": 103680, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 103680, "to": 109901, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 109901, "to": 116495, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 116495, "to": 123485, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 123485, "to": 130893, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 130893, "to": 138748, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 138748, "to": 147074, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 147074, "to": 155898, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 155898, "to": 165251, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 165251, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
    },
    "help_noTaxFree": {
      "label": "HECS, HELP, VET, SSL, TSL",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 37503, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 37503, "to": 46213, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 46213, "to": 50115, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 50115, "to": 54253, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 54253, "to": 58638, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 58638, "to": 63286, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 63286, "to": 68214, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 68214, "to": 73437, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 73437, "to": 78974, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 78974, "to": 84843, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 84843, "to": 91064, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 91064, "to": 97658, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 97658, "to": 104648, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 104648, "to": 112056, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 112056, "to": 119911, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 119911, "to": 128236, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 128236, "to": 137061, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 137061, "to": 146414, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 146414, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
    },

    "sfss": {
      "label": "SFSS",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 56340, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 56340, "to": 65050, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 65050, "to": 68952, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 68952, "to": 73090, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 73090, "to": 77475, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 77475, "to": 82123, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 82123, "to": 87051, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 87051, "to": 92274, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 92274, "to": 97811, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 97811, "to": 103680, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 103680, "to": 109901, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 109901, "to": 116495, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 116495, "to": 123485, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 123485, "to": 130893, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 130893, "to": 138748, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 138748, "to": 147074, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 147074, "to": 155898, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 155898, "to": 165251, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 165251, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
    },
    "sfss_noTaxFree": {
      "label": "SFSS ",
      "incremental": 0,
      "brackets": [
        { "from": 0, "to": 37503, "type": "fixed", "nearest": 1, "value": 0 },
        { "from": 37503, "to": 46213, "type": "percent", "nearest": 1, "value": 1 },
        { "from": 46213, "to": 50115, "type": "percent", "nearest": 1, "value": 2 },
        { "from": 50115, "to": 54253, "type": "percent", "nearest": 1, "value": 2.5 },
        { "from": 54253, "to": 58638, "type": "percent", "nearest": 1, "value": 3 },
        { "from": 58638, "to": 63286, "type": "percent", "nearest": 1, "value": 3.5 },
        { "from": 63286, "to": 68214, "type": "percent", "nearest": 1, "value": 4 },
        { "from": 68214, "to": 73437, "type": "percent", "nearest": 1, "value": 4.5 },
        { "from": 73437, "to": 78974, "type": "percent", "nearest": 1, "value": 5 },
        { "from": 78974, "to": 84843, "type": "percent", "nearest": 1, "value": 5.5 },
        { "from": 84843, "to": 91064, "type": "percent", "nearest": 1, "value": 6 },
        { "from": 91064, "to": 97658, "type": "percent", "nearest": 1, "value": 6.5 },
        { "from": 97658, "to": 104648, "type": "percent", "nearest": 1, "value": 7 },
        { "from": 104648, "to": 112056, "type": "percent", "nearest": 1, "value": 7.5 },
        { "from": 112056, "to": 119911, "type": "percent", "nearest": 1, "value": 8 },
        { "from": 119911, "to": 128236, "type": "percent", "nearest": 1, "value": 8.5 },
        { "from": 128236, "to": 137061, "type": "percent", "nearest": 1, "value": 9 },
        { "from": 137061, "to": 146414, "type": "percent", "nearest": 1, "value": 9.5 },
        { "from": 146414, "to": 0, "type": "percent", "nearest": 1, "value": 10 },
      ],
    }
  }



];