export const ANNUALLY = "Annually";
export const MONTHLY = "Monthly";
export const FORTNIGHTLY = "Fortnightly";
export const WEEKLY = "Weekly";
export const DAILY = "Daily";
export const HOURLY = "Hourly";

export const YEAR = "Year";
export const MONTH = "Month";
export const FORTNIGHT = "Fortnight";
export const WEEK = "Week";
export const DAY = "Day";
export const HOUR = "Hour";

export const DEFAULT_WEEKS_PER_YEAR = 52;
export const DEFAULT_FORTNIGHTS_PER_YEAR = 26;
export const DEFAULT_MONTHS_PER_YEAR = 12;

export const EXACT_WEEKS_PER_YEAR = 52.1429;
export const EXACT_FORTNIGHTS_PER_YEAR = 26.0893


export const FREQUENCY = [WEEK, FORTNIGHT, MONTH, YEAR];
export const HOURLY_FREQUENCY = [DAY, ...FREQUENCY]
export const OVERTIME_FREQUENCY = [DAY, WEEK, FORTNIGHT, MONTH, YEAR]
export const OVERTIME_TYPE_LOADING = "Loading";
export const OVERTIME_TYPE_HOURLY = HOURLY;
export const OVERTIME_TYPE = [OVERTIME_TYPE_LOADING, OVERTIME_TYPE_HOURLY];

export const FREQUENCY_DEFAULTS = [52, 26, 12, 1];
export const FREQUENCY_PRORATA = [WEEK, FORTNIGHT];
export const FREQUENCY_PRORATA_AMOUNT = ["Hours", "Days"];
export const PRORATA_LIMITS = [[38, 76], [5, 10]];
export const DAY_LIMITS = [7, 14, 31, 365];
export const ANNUAL_LIMITS = [52, 26, 12, 1];
export const HOUR_LIMITS = [168, 336, 728, 8736];

export const OVERTIME_DATA_HOURLY = { hourlyRate: 20, loadingRate: 1.5, hours: 1, frequency: WEEK, annualFrequency: FREQUENCY_DEFAULTS[0], type: OVERTIME_TYPE_LOADING, super:false };
export const OVERTIME_DATA_DAILY = { hourlyRate: 20, loadingRate: 1.5, hours: 1, frequency: WEEK, annualFrequency: FREQUENCY_DEFAULTS[0], type: OVERTIME_TYPE_HOURLY, super:false };


export const TAXABLE_INCOME = "Taxable Income"
export const SUPERANNUATION = "Superannuation"
export const TAX = "Tax"


export const FREQUENCY_DESCPIPTION = [ANNUALLY, MONTHLY, FORTNIGHTLY, WEEKLY];

export const NO_YTD = { a: 1, m: 1, f: 1, w: 1, d: 1 }


// calculations are performed on prescribed schedules: daily(casual), weekly, fortnightly, monthly, annually
export const CALCULATION_OBJECT = {
    a:0, // annually
    m:0, // monthly
    f:0, // fortnightly
    w:0, // weekly
    d:0, // daily
}

export const payOptions = [
  { title: ANNUALLY, type: "fulltime", tipLabel: "year", label: "Annual", default: 60000, range: "a" },
  { title: MONTHLY, type: "fulltime", tipLabel: "month", label: "Monthly", default: 5000, range: "m" },
  { title: FORTNIGHTLY, type: "fulltime", tipLabel: "fortnight", label: "Fortnightly", default: 1500, range: "f" },
  { title: WEEKLY, type: "fulltime", tipLabel: "week", label: "Weekly", default: 750, range: "w" },
  { title: DAILY, type: "daily", tipLabel: "day", label: "Daily", casual: true, default: 150, range: "d" },
  { title: HOURLY, type: "hourly", tipLabel: "hour", label: "Hourly", casual: true, default: 20, range: "d" },
  // { title: "Hourly/day", type:"hourly_day", tipLabel: "hour", label: "Hourly rate", casual: true, default: 20 },
]

export const novatedLeaseOptions = [
  ANNUALLY,
  MONTHLY,
  FORTNIGHTLY,
  WEEKLY,
]

export const salarySacraficeOptions = [
  ANNUALLY,
  MONTHLY,
  FORTNIGHTLY,
  WEEKLY,
  "Percent",
]

export const salaryOptions = [
  ANNUALLY,
  MONTHLY,
  FORTNIGHTLY,
  WEEKLY,
]


export const bonusIncomeOptions = [
  ANNUALLY,
  MONTHLY,
  FORTNIGHTLY,
  WEEKLY,
  "Percent",
]
export const bonusIncomeOptionsLabel = [
  "Annual Bonus",
  "Monthly Bonus",
  "Fortnightly Bonus",
  "Weekly Bonus",
  "Percentage Bonus",
]


export const salaryOptionsFrequency = [
  YEAR,
  MONTH,
  FORTNIGHT,
  WEEK,
]


// relates to medicareExemptionValue
export const medicareExpemptionOptions = [
  { label: "Half", value: 0.5 },
  { label: "Full", value: 1.0 }
]





export const FTB_CATEGORIES = [
  { from: 0, to: 13, type: "fixed", value: 0, label: "Less than regular care" },
  { from: 14, to: 34, type: "fixed", value: 24, label: "Regular care" },
  { from: 35, to: 47, type: "rate", nearest: 1, start: 25, end: 50, value: 200, label: "Shared care" },
  { from: 48, to: 52, type: "fixed", value: 50, label: "Shared care" },
  { from: 53, to: 65, type: "rate", nearest: 1, start: 51, end: 76, value: 200, label: "Shared care" },
  { from: 66, to: 86, type: "fixed", value: 76, label: "Primary care" },
  { from: 87, to: 100, type: "fixed", value: 100, label: "More than Primary care" },
]


export const FTB_A = {
  //
  //https://guides.dss.gov.au/family-assistance-guide/3/1/1/20
  baseRate: 71.26, //68.46, //63.56 updated October 23,
  maxAge: 19,
  teenage: 13,
  IFA: 65189, //62634, // 58107, // incomeFreeArea ( 0 dependants income limit)
  HIFA: 115997, //111398, // 103368, // higherIncomeFreeArea ( 0 dependants upper limit)

  IFA_RATE: 0.2,
  HIFA_RATE: 0.3,
  MIFA_RATE: 0.5,

  //lowerLimit: 58107,
  //incomeThreshold : 103368,
  // maxRate: [
  //     { from: 0, to: 12, value: 197.96, annual: 5161.10 },
  //     { from: 13, to: 15, value: 257.46, annual: 6712.35 },
  //     { from: 16, to: 19, value: 257.46, annual: 6712.35 },
  // ],
  //   maxRate: [
  //     { from: 0, to: 12, value: 213.36 },
  //     { from: 13, to: 15, value: 277.48 },
  //     { from: 16, to: 19, value: 277.48},
  // ],
  // updated 2 August 2024
  maxRate: [
    { from: 0, to: 12, value: 222.04 },
    { from: 13, to: 15, value: 288.82 },
    { from: 16, to: 19, value: 288.82},
  ],

  MIFA_single: 1960.05, //1883.40,// 1752,
  MIFA_couple: 3920.10, //3766.8, //3504,
  MIFA_additional_children: 653.35, //627.8, //584,

  //incomeLimit - Base rate is applied after this limit (rare)
  // Array indexed by teen : child  split
  // 1 dependant child:teen
  // [0:1, 1:0]
  // 2 dependants child:teen
  // [0:2, 1:1, 2:0]
  dependents: [
      // 0 dependents
      {
          incomeLimit: [65189], //[62634], //[58107],
          upperLimit: [115997], //[111398], //[103368]
      },
      // 1 dependents
      {
          incomeLimit: [93550, 84845], //[83385, 75628], //limit to start reducing // array of child count
          upperLimit: [122190, 122190],// [108892, 108892], // upper limit to qualify
      },
      // 2 (or more) dependents
      {
          incomeLimit: [113205, 113205, 104500], // [100905, 100905, 93148], // [0 child/2 teen, 1 child/1 teen), 2 child/0 teen]
          upperLimit: [132325, 128383, 128383], // [117944, 144416, 144416],
      },
      // 3 dependents
      {
          incomeLimit: [113205], // [103368], // [0 child/3 teen, 1 child/2 teen), 2 child/1 teen, 3 child/0 teen]
          upperLimit: [157425, 151621, 145818, 140014],// [140319, 135148, 129977, 124806],
      },
      // 4 dependents
      {
          incomeLimit: [113205], //[103368], // [0 child/3 teen, 1 child/2 teen), 2 child/1 teen, 3 child/0 teen]
          upperLimit: [176721, 176721, 170918, 165114, 165114],// [157522, 157522, 152351, 147181, 147181],
      },
      // 5 dependents
      {
          incomeLimit: [113205], //[103368], // [0:3 teen, 1:2 teen, 2:1 teen, 3:0 teen]
          upperLimit: [196018, 196018, 196018, 190214, 190214, 190214], //[0:3, 1:2, 2:1, 3:0]
      },
      // 6 dependents
      {
          incomeLimit: [113205], // [103368], // [0 child/3 teen, 1 child/2 teen), 2 child/1 teen, 3 child/0 teen]
          upperLimit: [215314], //[191930],
      },

  ]
}

export const FTB_A_SUPPLEMENT = {
  cap: 80000,
  perChild: 916.15, // 879.65, // 817.6
}

export const FTB_B = {
  // maxRate: [
  //     { from: 0, to: 4, value: 168.28 },
  //     { from: 5, to: 18, value: 117.46 },
  //     { from: 19, to: 0, value: 0 },
  // ],
//   maxRate: [
//     { from: 0, to: 4, value: 181.44 },
//     { from: 5, to: 18, value: 126.56 },
//     { from: 19, to: 0, value: 0 },
// ],
// 2 August 2024
maxRate: [
  { from: 0, to: 4, value: 188.86 },
  { from: 5, to: 18, value: 131.74},
  { from: 19, to: 0, value: 0 },
],
  incomeCap: 117194, // 112578, // 104432,

  partnerIncomeFreeArea: 6789, //6497, //6059,
  partnerIncomeFreeArea_rate: 0.2,

  youngestAge: {
      single: 18,
      couple: 13,
      grandparent: 18,
  }

}

export const FTB_B_SUPPLEMENT = {
  cap: 80000,
  perFamily: 448.95, //430.70// 397.85
}







export const CHILD_OBJ = {age:10}



// reset Superannuation
// Use rate from tax Data for the current year
export const InitialState = {
  count: 0,

  weeksPerYear:DEFAULT_WEEKS_PER_YEAR,
  fortnightsPerYear:DEFAULT_FORTNIGHTS_PER_YEAR,

  adjustment: 0, // feature to quickly add or subtract an amount to the current salary (regardless of range a, m, f, w...) Reset whenever the income is changed

  annuated: true, // the calculations can be derived from annual amounts (otherwise calculated at each)
  PAYG: true, // calculator uses ATO PAYG rates
  salary: 60000, // value in the salary input field
  salaryAnnual: 60000, // annual normalised salary as it appears in the inputfield

  // days per... frequency, frequency per annual
  // casualDaily: { days: 5, frequency: OVERTIME_FREQUENCY[0], annual: FREQUENCY_DEFAULTS[0] },
  // hours per day, days per... frequency, frequency per annual
  // casualHourly: { hours: 8, days:5, frequency: OVERTIME_FREQUENCY[0], annual: FREQUENCY_DEFAULTS[0] },

  fulltime: false, // used only to show the casual input options
  casual: { hours: 38, days:5, frequency: FREQUENCY[0], frequency_day: FREQUENCY[0], annual: FREQUENCY_DEFAULTS[0] },
  unpaidPublicHolidays:11,
  unpaidLeave:10,
  overtimeSG :{...CALCULATION_OBJECT}, // Overime Super Guarentee

  adjustProRata: false,
  proRataAmount: 38,
  proRataPercent: 0,
  proRataAmountFrequency: 0,
  proRataFrequency: 1,

  medicareExemption: false,
  spouse: false,
  spouseIncome: 0,
  dependants: false,
  dependantsCount: 1,
  childSupport: 0,
  medicareExemptionValue: 1,

  medicareDescription: "Single, no dependants",

  SAPTO: false,
  spouseSeparated: false,

  noSuperannuation: false,
  adjustSuperannuationCarryForward: false,
  superannuationCarryForward: 0,

  adjustSpouseSuper: false,
  spouseSuperAmount: 0,

  adjustSuperannuation: false,
  additionalSuper: 0,
  additionalSuperMaximise: false,
  superannuationGuarantee:0,
  concessionalAdditionalSuper: 0,
  superannuationUntaxedNonConcessional: 0, // non concessional from sacrafice or excess wages)

  adjustSuperannuationRate: false,
  superannuationRate: 11.5,
  reportableSuper: true, 

  adjustSalaryScaracfice: false,
  salaryScaracficeAmount: 0,
  salarySacraficeOption: 0,
  salaryScaracficeMaximise:false,


  salarySacraficeIncomeAmount: 0,
  salarySacraficeIncomeOption: 0,
  salarySacraficeIncomeSavings: 0,


  // Novated Lease
  novatedLeaseIncluded:false,
  novatedLeaseAmount:0,
  novatedLeaseOption:0,
  novatedLeaseSavings:0,

  includesSuperannuation: false,
  //over65: false,
  //over71: false,
  superannuationCoContributionAvailable: false,
  superannuationCoContributionApply: true,
  superannuationCoContributionNonConcessional: 0,
  superannuationConcessionalSavings: 0,
  superannuationConcessionalNetSavings: 0,
  superannuationSacraficeSavings: 0,
  superannuationSacraficeNetSavings: 0,

  benefits: 0, // Governemnt benefits, pension

  adjustDeductions: false,
  adjustFringeBenefits: false,
  includeFringeBenefits: false,
  fringeBenefits: 0, // reportable grossed up value
  fringeBenefitsFrequency:0,
  fringeBenefitsAnnual: {...CALCULATION_OBJECT},

  adjustReportableFringeBenefits:false,
  reportableFringeBenefits:0,
  
  taxableDeductions: 0,

  adjustOtherIncome:false,
  otherIncome:0,
  otherIncomeTax: 0,
  
  capitalGains:0,

  taxableDeductionsTax:0,
  capitalGainsTax:0,
  

  allowanceIncome: 0,
  adjustAllowanceIncome: false,
  allowanceIncomeOption: 0,
  allowanceIncomeTaxable:false,

  backpacker: false, // backpacker tax rates
  WHMIncomeOption:0, // annual income range
  haveTFN: true,
  nonResident: false, // non-resident tax rates
  noTaxFree: false, // option to not claim the tax free threshold
  notForProfit: false, // depricated
  HELP: false, // all student loan repayments HELP, VSL, SFSS, SSL, ABSTUDY SSL, TSL
  SFSS: false,  // legacy sudent financial suppliment scheme
  withhold: false, // not used only related to specific offsetds
  year: 2021,

  payOption: 0, // index of payOptions annual income

  // overtime: [{rate: 0, rateMultiple: 0, hpd: 7.5, hpw: 38, super:false}],
  overtime: [],


  hasBonus:false,
  bonusIncome:0, // same calucations as Other Income
  bonusIncomeObj: {...CALCULATION_OBJECT},
  bonusIncomeTax:0,
  bonusIncomeOption:0, 

  businessIncome: 0,
  businessIncomeTax:0,
  businessIncomeLoss: 0,
  businessIncomeLossTax:0,
  includeGST:false,
  includeSMTO: true,
  taxCredits:0,
  frankingCredits:0,


  // Family Tax Benefits
  maintenance:0,
  maintenance_partner:0,
  ftb_carePercent:100,
  ftb_careRatio:1,
  ftb_careLabel:'',
  includeFTB:false,
  children:[],
  ftb_A:0,
  ftb_A_Supplement:0,
  ftb_B:0,
  ftb_B_Supplement:0,
  ftb:{...CALCULATION_OBJECT},


  // annual figures
  division293Income: 0,
  division293: 0,
  listo: 0, // Low income super annuation tax offset
  otherTaxesAndLevies: 0,
  lito: 0,
  lamito: 0,
  mito: 0,
  mawto: 0,
  sapto: 0, // value of sapto (as opposed to SAPTO which is the eligibility option)
  saptoExcess: 0, // potential excess sapto can be transferred to spouse

  hasPrivateHealthcare: true,
  medicareSurcharge: 0, // this is what it could be
  medicareSurchargeLiability: 0, // this is what it is
  medicareFamilyAdjustment: false, // only applicable on application of form
  applyMedicareAdjustment: false, // option to apply the adjustment


  // Superannuation categories
  superannuationGuaranteeCap: true, // Maximum super contribution base
  superannuationCoContribution: 0,

  superannuationConcessional: 0,
  superannuationConcessionalTax: 0,
  superannationConcessionalRemaining: 0,

  superannationNonConcessional: 0,
  //superannationTaxableNonConcessional: 0, // non-concessional without voluntary, which has already been taxed
  superannationNonConcessionalRemaining: 0,


  superannuationExcess: 0,
  superannuationExcessTax: 0,


  warnings: {
    superannuation: [],
    nonConcessionalCap: false,// over the limit
    superannuationGuarantee: false, // under the guarantee
    medicareSurcharge: false, // Over the limit if not private healthcare
    division293: false, // additional tax on super outside fund
    extraPayment: false,
    maxSalarySacrafice: false,
    superannuationCapped: false,
    maximunContributionsCapActive: false,
  },


  taxableDeducations: 0,
  taxableIncome: 0, // Earnings minus deductions
  netIncome: 0, // net without voluntary super and deducitons
  marginalTaxRate:0,

  //https://www.ato.gov.au/Individuals/Tax-return/2019/Tax-return/Adjusted-taxable-income-(ATI)-for-you-and-your-dependants-2019/
  // adjustedTaxableIncome: 0, // used to detemine medicare, HELP repayments
  adjustedAnnualTaxableIncome:0, // also includes other business income
  medicareSurchargeIncome:0,
  rebateIncome: 0,  // Used for SAPTO
  familyIncome: 0,
  spouseSuperTaxOffset: 0,
  repaymentIncome: {...CALCULATION_OBJECT},

  // payg figures
  baseSalary: {...CALCULATION_OBJECT}, // this includes overtime
  overtimeSalary: {...CALCULATION_OBJECT},
  income: {...CALCULATION_OBJECT}, // taxable income
  salarySacraficeIncome: {...CALCULATION_OBJECT}, // salary sacrafice income
  novatedLease:{...CALCULATION_OBJECT}, // novated lease

  net: {...CALCULATION_OBJECT},

  superannuation: {...CALCULATION_OBJECT}, // Superannuation guarantee
  superannuationReportable: {...CALCULATION_OBJECT},  // any amout of super over the guarentee rate ( excluded Salary sacraficed)
  superannuationSacrafice: {...CALCULATION_OBJECT}, // Superannuation salary sacrafice
  superannuationTax: {...CALCULATION_OBJECT},
  totalSuperannuation: {...CALCULATION_OBJECT},

  incomeTax: {...CALCULATION_OBJECT},
  extraWithholdingTax: {...CALCULATION_OBJECT}, // used for 26/53 week years ONLY
  grossTax: {...CALCULATION_OBJECT},
  allowance: {...CALCULATION_OBJECT},
  otherTax: {...CALCULATION_OBJECT},
  offsets: {...CALCULATION_OBJECT},
  medicare: {...CALCULATION_OBJECT},
  medicareAdjustment: {...CALCULATION_OBJECT},
  levies: {...CALCULATION_OBJECT},
  help: {...CALCULATION_OBJECT},
  sfss: {...CALCULATION_OBJECT},

  payments: { d: 260, w: 52, f: 26, m: 12, a: 1 },
  YTD: { d:1, w: 1, f: 1, m: 1, a: 1 },
  ytd:false,
  ytdFrom: '',
  ytdTo: '',

  deductions: {...CALCULATION_OBJECT} // not being used right now
}


