import React from 'react'
import {
    useMediaQuery,
    useTheme,
    IconButton,
} from '@material-ui/core';
import { Link } from 'react-router-dom'

import { MORTAGE_MONSTER } from '../../constants'
import logo from '../../assets/mortgagemonster/Logo_white.svg'

const MM = () => {

    const theme = useTheme();
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const imageSize = small ? 100 : 120

    return (
        <div style={{ marginTop: -10 }}>
             <Link reloadDocument to={MORTAGE_MONSTER} target="_self" rel="noopener noreferrer" >
           <IconButton disableRipple style={{ textAlign: 'left', height: imageSize * 0.5, width: imageSize, backgroundColor: 'transparent' }} aria-label="budget 2022-23">
                <img height={imageSize * 0.5} width={imageSize} src={logo} alt={"mortgage.monster"} />
            </IconButton>
             </Link> 
        </div>
    )

}

export default MM;