import { createTheme } from '@material-ui/core/styles';

const fontHeading = {
    fontFamily: [
       // 'Ubuntu',
        //'Noto Sans JP',
        'Arial',
        'sans-serif',
    ],
    // textShadow: '2px 1px 1px rgba(0,0,0,0.1)',
    textShadow: '-1px -1px 1px rgba(255,255,255,0.25)',
}


const fontBody = {
    fontFamily: [
       // 'Noto Sans JP',
       // 'Ubuntu',
        'Arial',
        'sans-serif',
    ],
}

const palette = {
    primary: {
        light: '#f4c244',
        main: '#f2b440',
        dark: "#c68712", //'#ee9337',
        contrastText:"#333333",

         // used for colour keys on summary data
        //  "100":'#FDC008',
        //  "200":'#FEAE00',
        //  "300": '#FF8E07',
        //  "400":'#FF7901',
        //  "500":'#E86D00',

         '100':'#f4c244',
         '200':'#f4b843',
         '300':'#eca837',
         '400':'#e2942a',
         '500':'#d77f1c',
         '600':'#ce6e0e',
         '700':'#c76005',
    },
    secondary: {
        light: '#9abb57',
        main: '#6f9334',
        dark: '#50612e',
        contrastText:"#FAFAFA",

         // used for colour keys on summary data
        "100":'#a2ba64',
        "200":'#8aa454',
        "300":'#708a45',
        "400":'#5a733c',
        "500":'#4c5f33',
        "600":'#3b4826',
        '700':'#2a351c',
    },
    tertiary: {
        light: '#4DBFE4',
        main: '#33A2C6',
        dark: '#24738C',
        contrastText:"#FAFAFA",

         // used for colour keys on summary data
         "100":'#4DBFE4',
         "200":'#37a9cd',
         "300":'#2d90b0',
         "400":'#25748d',
         "500":'#185a6e',
         "600":'#0b4050',
         "700":'#03313F',
    },

    background: {
        // paper: 'rgb(250, 250, 250)',// '#FFFFFF',
        default: '#FAFAFA',
        paper: '#F0F0F0',
        overlay: '#DDDDDD',
        overlayFaint: '#DFDFDF',//'#2a2a2a',
    }
}


// setup themes
export default createTheme({

    props:{
        MuiButtonBase: {
            TouchRippleProps: {
                 style: {color: palette.primary.main}
                
            }
        },
    },

    typography: {
        
        button: {
            fontWeight: '700',
            ...fontHeading,
            fontSize: 14,
          },
        h1:{
            ...fontHeading,
            fontSize: 22,
            fontWeight: 700,
            letterSpacing: "-0.5px",
            textShadow: '-1px -1px 1px rgba(255,255,255,0.25)',
        },
        h2:{
            ...fontHeading,
            fontSize: 16,
            fontWeight: 700,
            letterSpacing: "-0.5px",
        },
        h3:{
            ...fontHeading,
            fontSize: 14,
            fontWeight: 700,
            letterSpacing: "-0.25px",
        },

        h4:{
            ...fontHeading,
            fontSize: 18,
            fontWeight: 500,
            letterSpacing: "-0.25px",
        },
        h5:{
            ...fontBody,
            fontSize: 18,
            opacity:0.8,
            fontWeight: 400,
            letterSpacing: "-0.25px",
            textShadow: '1px 1px 1px rgba(0,0,0,0.05)',
        },

        subtitle1:{
            ...fontHeading,
            fontSize: 14,
            fontWeight: 700,
            letterSpacing: "-0.25px"
        },
        
        body1:{
            ...fontBody,
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: "-0.1px"
        },
        body2:{
            ...fontBody,
            fontSize: 11,
            fontWeight: 400,
            letterSpacing: "-0.1px"
        },
        body3:{
            ...fontBody,
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: "-0.1px",
        },
        // caption:{
        //     ...fontBody,
        //     fontSize: 18,
        //     opacity:0.8,
        //     fontWeight: 400,
        //     letterSpacing: "-0.25px",
        //     textShadow: '1px 1px 1px rgba(0,0,0,0.05)',
        // },
    },
    palette: {
        ...palette,

        type: 'light',
        grey:{
            // "300":"#2f2f2f", // lighter
            "900":"#999999", // darker
        },

        text: {
            primary:"#333333",
            secondary:"#FAFAFA",
            link:"#c68712",
            alert:palette.primary.main,
        },
       
    },
    transitions: {
        duration: {
            standard: 500,
        }
    }
});